import { computed } from 'vue'
import { useStore, useEventBus } from '@login/hooks/utils'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getSessionStorage } from '@shein/common-function'
const { IS_RW } = gbCommonInfo

export default function useRetainModalHook () {

  const store = useStore()

  const commonAbt = computed(() => store.state.login.commonAbt)
  const isShowRetainModal = computed(() => store.state.login.isShowRetainModal)
  const RetainModalConfig = computed(() => store.state.login.RetainModalConfig)


  const handleCloseRetainModal = () => {
    if(isLogin()) return
    const LoginRegisterRetian = commonAbt.value?.LoginRegisterRetian
    const LoginRegisterRetianPops = commonAbt.value?.LoginRegisterRetianPops
    if (LoginRegisterRetian == 'on' && isShowRetainModal.value != true && !IS_RW) {
      if(!LoginRegisterRetianPops) return
      const result = LoginRegisterRetianPops.split(',')
      const cacheRetainModal = getSessionStorage('cacheRetainModal') || []
      const type = RetainModalConfig.value?.type
      if (result.includes(type) && !cacheRetainModal.includes(type)) {
        store.commit('login/assignState', { showPointRetainModal: true })
        if (!cacheRetainModal.includes(RetainModalConfig.value?.type)) {
          store.commit('login/assignState', { isShowRetainModal: true })
        }
      }
    }
  }

  return { handleCloseRetainModal }
}
