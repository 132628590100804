export const showInputTips =  (tip, msg, show = true) => {
  tip.show = show
  tip.txt = msg
}


export const formatAccountList = (list) => {
  if (!Array.isArray(list) || list.length < 1) return []
  const filteredArray = list.filter(v => {
    if(v.alias_type == 2){
      return v.encryption_alias && v.desensitize_alias && v.uid && v.area_code && v.area_abbr
    } else {
      return v.encryption_alias && v.desensitize_alias && v.uid 
    }
  })
  return filteredArray
}


export const formatThirdLoginType = (alias_type) => {
  let type = ''
  switch(alias_type) {
    case 3:
      type = 12
      break
    case 4:
      type = 11
      break
    case 6:
      type = 15
      break
    case 7:
      type = 13
      break
    case 8:
      type = 16
      break
    case 9:
      type = 17
      break
    default:
      type = false
      break
  }
  return type
}

export const formatLoginType = (types) => {
  let type = ''
  switch(+types) {
    case 12:
      type = 3
      break
    case 11:
      type = 4
      break
    case 14:
      type = 6
      break
    case 13:
      type = 7
      break
    case 16:
      type = 8
      break
    case 17:
      type = 9
      break
    default:
      type = false
      break
  }
  return type
}
