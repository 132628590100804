<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle
      cx="7.99967"
      cy="8.00016"
      r="6.66667"
      fill="#198055"
    />
    <circle
      cx="7.99967"
      cy="8.00016"
      r="6.91667"
      stroke="white"
      stroke-opacity="0.3"
      stroke-width="0.5"
    />
    <path
      d="M11.1823 5.87868L6.93968 10.1213L4.81836 8"
      stroke="white"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'SelectedIcon'
}
</script>
