<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 1H23.75"
      stroke="url(#paint0_linear_561_14673)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_561_14673"
        x1="0.25"
        y1="1"
        x2="23.25"
        y2="0.999997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop
          offset="1"
          stop-opacity="0.16"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DividerLeftIcon'
}
</script>
