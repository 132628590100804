<template>
  <div class="page__login_button">
    <s-button
      :type="type"
      :disabled="isDisabled"
      :loading="isLoading"
      width="100%"
      @click="handleClick"
    >
      <span :class="isShowUsed ? 'btn__text_style' : ''">{{ text }}</span>
    </s-button>
    <slot name="extra"></slot>
  </div>
</template>

<script setup>
defineProps({
  type: { type: Array, default: () => ['primary'] },
  text: { type: String, default: '' },
  isDisabled: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
  isShowUsed: { type: Boolean, default: false },
})
const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}

// eslint-disable-next-line semi
;
</script>
<script>
export default {
  name: 'LoginButtonIndex'
}
</script>

<style lang="less" scoped>
.page__login_button{
  position: relative;
  .btn__text_style{
    display: inline-block;
    transform: translateY(-4px);
  }
}
</style>
