<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 3C1.5 2.72386 1.72386 2.5 2 2.5H11C11.2761 2.5 11.5 2.72386 11.5 3V5.75C11.0858 5.75 10.75 6.08579 10.75 6.5C10.75 6.91421 11.0858 7.25 11.5 7.25V10C11.5 10.2761 11.2761 10.5 11 10.5H2C1.72386 10.5 1.5 10.2761 1.5 10V7.25C1.91421 7.25 2.25 6.91421 2.25 6.5C2.25 6.08579 1.91421 5.75 1.5 5.75V3Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.35737 3.72014C8.08641 3.52939 7.71323 3.59519 7.52385 3.86711L4.51987 8.18046C4.33049 8.45238 4.39662 8.82746 4.66758 9.01821C4.93854 9.20897 5.31172 9.14317 5.5011 8.87124L8.50508 4.55789C8.69446 4.28597 8.62833 3.9109 8.35737 3.72014ZM4.72214 5.61102C5.21306 5.61102 5.61103 5.21306 5.61103 4.72214C5.61103 4.23122 5.21306 3.83325 4.72214 3.83325C4.23122 3.83325 3.83325 4.23122 3.83325 4.72214C3.83325 5.21306 4.23122 5.61102 4.72214 5.61102ZM8.2778 9.16669C8.76872 9.16669 9.16669 8.76872 9.16669 8.2778C9.16669 7.78688 8.76872 7.38891 8.2778 7.38891C7.78689 7.38891 7.38892 7.78688 7.38892 8.2778C7.38892 8.76872 7.78689 9.16669 8.2778 9.16669Z"
      fill="#FA6338"
    />
  </svg>
</template>

<script>
export default {
  name: 'SingleCouponIcon'
}
</script>
