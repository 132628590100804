import { request } from './index'
import { commonDealWith } from '@login/service.js'

export async function switchLoginSer(params) {
  const data = await request('post', `/api/auth/switchLogin/update`, params)
  commonDealWith(data)
  return data
}


export async function removeAccountSer(params) {
  const data = await request('post', `/api/auth/removeAccount/update`, params)
  return data
}
