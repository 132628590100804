import { request } from './index'


export async function getCouponsData(params) {
  const data = request('post', `/api/auth/newUser/retain/get`, params)
  return data
}

export const getRegisterData = async () => {
  const data = await request('post', `/api/auth/newUser/recommend/get`)
  return data
}

