var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-dialog',{staticClass:"page__login-newPrivacyModal",attrs:{"visible":_vm.newPrivacyModal.show,"append-to-body":true,"da-expose-code":"page-login-pop-expose-events","immediately-render":true}},[(_vm.SiteUID == 'mkr')?[_c('template',{slot:"title"},[_vm._v("\n      "+_vm._s(_vm.langText.SHEIN_KEY_PWA_31879)+"\n    ")]),_vm._v(" "),_c('kr-privacy-common',{attrs:{"language":_vm.langText,"from":"login"},on:{"change-handle":_vm.changeHandle}})]:_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.combineNewPrivacyLanguage({
        abt: 'yes',
        from: 'pop',
        type: _vm.newPrivacyAbtFrom,
        location: `login_privacy_pop`,
        language: _vm.langText
      })
    )},on:{"click":_vm.handleNewPrivacyClick}}),_vm._v(" "),_c('s-button-group',{staticStyle:{"margin":"15px 0"},attrs:{"num":2,"width":"100%"}},[_c('s-button-group-item',{attrs:{"type":['primary', 'H72PX']},on:{"click":_vm.agreeNewPrivacy}},[_vm._v("\n      "+_vm._s(_vm.langText[_vm.SiteUID == 'mkr' ? 'SHEIN_KEY_PWA_15578' : 'SHEIN_KEY_PWA_18126'])+"\n    ")]),_vm._v(" "),_c('s-button-group-item',{attrs:{"type":['H72PX']},on:{"click":_vm.cancelNewPrivacy}},[_vm._v("\n      "+_vm._s(_vm.langText[_vm.SiteUID == 'mkr' ? 'SHEIN_KEY_PWA_27702' : 'SHEIN_KEY_PWA_18699'])+"\n    ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }