<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.25 1H0.25"
      stroke="url(#paint0_linear_561_14674)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_561_14674"
        x1="23.75"
        y1="1"
        x2="0.75"
        y2="0.999997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop
          offset="1"
          stop-opacity="0.16"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DividerRightIcon'
}
</script>
