function createMutations () {
  const mutations = {
    // 更新 ssr state数据
    initSsrData (state, context = {}) {
      const { LoginLangText = {}, LoginPreloadData = {} } = context
      state.langText = LoginLangText
      state.isGoogleSupport = LoginPreloadData?.isGoogleSupport
      state.WEB_CLIENT = LoginPreloadData?.WEB_CLIENT
    },

    setPhoneLoginCodeMode (state, v) {
      state.phoneLoginCodeMode = v
    },
    setNewPrivacyAbt(state, data = {}) {
      const { param, loading, origin } = data
      state.newPrivacyAbt.type = param
      state.newPrivacyAbt.loading = loading
      if (origin) state.newPrivacyAbt.origin = origin
    },
    setLangText(state, data = {}) {
      state.langText = data
    },
    setAreaCode (state, data) {
      const { code, abbr } = data
      state.areaCode.code = code
      state.areaCode.abbr = abbr
    },
    setAreaCodePop (state, data) {
      if (typeof data == 'boolean') {
        state.showAreaPop = data
        state.areaCode.cb = null
        return
      }
      const { val, cb } = data
      state.showAreaPop = val
    
      if (typeof cb == 'function' ) state.areaCode.cb = cb
      else state.areaCode.cb = null
    },
    setCountryCodes (state, data) {
      state.countryCodes = data
    },
    setPhoneAbt (state, param) {
      const keys = param.split('&')
      keys.forEach(str => {
        const [k, v] = str.split('=')
        if (state.phoneAbt.hasOwnProperty(k) && v) {
          state.phoneAbt[k] = v
        } 
      })
    },
    changeDialogs (state, data = {}) {
      const { key, val } = data
      const v = state.dialogs[key] || {}
      state.dialogs[key] = { ...v, ...val }
    },
    setPhoneBind (state, data = {}) {
      state.phoneBind = { ...state.phoneBind, ...data }
    },
    setForgetPassword (state, data = {}) {
      state.forgetPassword = { ...state.forgetPassword, ...data }
    },
    setPhonePasswordChange (state, data = {}) {
      state.phonePasswordChange = { ...state.phonePasswordChange, ...data }
    },
    setCommonAbt (state, data) {
      const { key, val } = data
      state.commonAbt[key] = val
    },
    removeAccountListItem(state, val){
      const history_account_list = state?.accountList
      const indexValue = history_account_list?.findIndex(v => v.uid == val)
      if(indexValue > -1){
        history_account_list?.splice(indexValue, 1)
      }
    },
    updateAccountListItem(state, val){
      const history_account_list = state?.accountList || []
      history_account_list?.forEach(v => {
        if(v.uid == val){
          v.is_remember = '0'
        }
      })
    },
    setFuseAbtResult(state, data) {
      const { key, val } = data
      state.fuseAbtResult[key] = val
    },
    setSwitchSiteAbt (state, str) {
      const loginAbt = str.indexOf('login=on') > -1 ? 'on' : 'off'
      const registerAbt = str.indexOf('register=on') > -1 ? 'on' : 'off'
      const phoneLoginAbt = str.indexOf('phone_login=on') > -1 ? 'on' : 'off'
      const phoneRegisterAbt = str.indexOf('phone_register=on') > -1 ? 'on' : 'off'
      state.commonAbt.switchSite = { login: loginAbt, register: registerAbt, phone_login: phoneLoginAbt, phone_register: phoneRegisterAbt }
    },
    setDefaultLocation (state, data = {}) {
      state.defaultLocation = { ...state.defaultLocation, ...data }
      if (typeof window != 'undefined') {
        window.localStorage.setItem('LOCATION_CURRENT', JSON.stringify(state.defaultLocation))
      }
    },
    setSupportLocation (state, data = []) {
      state.supportLocation = data
    },
    setRelatedAcc (state, data = {}) {
      state.relatedAcc = { ...state.relatedAcc, ...data }
    },
    setIsAsComponent (state, val) {
      state.isAsComponent = val
    },
    setShowIndex (state, val) {
      state.showIndex = val
    },
    setNewPrivacyModal (state, val) {
      let show = false
      if (typeof val.show == 'undefined') show = true
      else show = val.show
      state.newPrivacyModal = { ...state.newPrivacyModal, ...val, show }
    },
    setRegisterPrivacyModal (state, val) {
      let show = false
      if (typeof val.show == 'undefined') show = true
      else show = val.show
      state.registerPrivacyModal = { ...state.registerPrivacyModal, ...val, show }
    },
    setInstanceName (state, v) {
      state.instancename = v
    },
    setPreloadData (state, v = {}) {
      state.preloadData = { ...state.preloadData, ...v }
    },
    setNewUserRights (state, v) {
      state.newUserRights.abt = v.abt || 'off'
      state.newUserRights.items = v.items || []
    },
    setLoginPopConfig(state, data = {}) {
      state.loginPopConfig = { ...state.loginPopConfig, ...data }
    },
    setnewUIStates (state, value = {}) {
      state.newUIStates = Object.assign({}, state.newUIStates, value)
    },
    setSwitchAccount (state, value = {}) {
      state.switchAccount = Object.assign({}, state.switchAccount, value)
    },
    assignState(state, payload) {
      Object.assign(state, payload)
    },
    setPointRetainModal(state, payload) {
      state.showPointRetainModal = payload
    },
    setHelpPageModal(state, payload) {
      state.showPointRetainModal = payload
    },
    setRetainModalConfig (state, data = {}) {
      state.RetainModalConfig = { ...state.RetainModalConfig, ...data }
    },
    setLoginButtonPoint (state, data = {}) {
      state.LoginButtonPoint = { ...state.LoginButtonPoint, ...data }
    },
    setHelpPageConfig (state, data = {}) {
      state.helpPageConfig = { ...state.helpPageConfig, ...data }
    },
    setSuccessPopConfig (state, data = {}) {
      state.registerSuccessPopConfig = { ...state.registerSuccessPopConfig, ...data }
    },
    setLoginButtonAnimation (state, data) {
      state.LoginButtonAnimation = [...state.LoginButtonAnimation, ...data ]
    },
    setPublicLocalData(state, data = {}) {
      state.publicLocalData = data
    }
  }
  return mutations
}

export default createMutations
