<template>
  <div class="compatible-coupon_container">
    <CompatibleCouponBg ref="CompatibleCouponBgRef" />
    <div
      class="compatible-coupon_box"
      :style="{height: `${heightValue}px`}"
    >
      <div class="compatible-coupon_right">
        <div class="coupon-right_top">
          <p>{{ langText?.SHEIN_KEY_PWA_29165 }}</p>
        </div>
        <template v-if="configData?.couponData?.type == 2">
          <div class="coupon-right_num">
            <span class="coupon-right_discount">{{ configData?.couponData?.value }}</span>
            <span class="coupon-right_symbol">{{ '%' }}</span>
          </div>
          <div class="coupon-right_bottom">
            <p>{{ langText?.SHEIN_KEY_PWA_29164 }}</p>
          </div>
        </template>
        <template v-if="configData?.couponData?.type == 1">
          <div class="coupon-price_compatible">
            <p>
              {{ configData?.couponData?.amountWithSymbol }}
            </p>
          </div>
        </template>
      </div>
      <div class="compatible-coupon_left">
        <div class="coupon-left_title">
          <p>{{ langText?.SHEIN_KEY_PWA_29158 }}</p>
        </div>
        <div class="coupon-left_desc">
          <p>{{ configData?.couponData?.desc }}</p>
        </div>
        <div
          class="coupon-left_btn"
          @click="handleUseCopon"
        >
          <p>{{ langText?.SHEIN_KEY_PWA_29181 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, nextTick } from 'vue'
import { clickNewUserCoupon } from '@login/utils/loginAnalysis'
import CompatibleCouponBg from './Icon/CompatibleCouponBg'

const props = defineProps({
  langText: {
    type: Object,
    default: () => {}
  },
  configData: {
    type: Object,
    default: () => {}
  }
})

const CompatibleCouponBgRef = ref(null)
const heightValue = ref('')


const handleUseCopon = async () => {
  const AddOnItem =  (await import('public/src/pages/common/add-on/index.js')).default
  AddOnItem.open({
    props: {
      type: 'coupon',
      coupon: props.configData?.couponData?.couponCode,
      queryInfo: {
        addOnType: 2, // 问产品拿
        sceneId: 152, // 推荐场景id
        goodsIds: [],
        cateIds: [],
        direct_tag: props.configData?.couponData?.direct_tag,
        return_tag: props.configData?.couponData?.return_tag,
        adp: [], 
      },
      saInfo: {
        // 主要用于埋点
        activity_from: 'registration_success_add',
        state: 'registration_success_add'
      }
    },
    on: {
      close () {
      }
    }
  })
  clickNewUserCoupon()
}


const updateViewportHeight = () => {
  nextTick(() => {
    const height = CompatibleCouponBgRef.value.$el.clientHeight
    heightValue.value = height
  })
}

onMounted(() => {
  window.addEventListener('resize', updateViewportHeight)
  updateViewportHeight()
})
onUnmounted(() => {
  window.removeEventListener('resize', updateViewportHeight)
})
// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'CompatibleCoupon'
}
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.compatible-coupon_container{
  margin-top: 20 / @vw;
  position: relative;
  width: 100%;
  height: auto;
}
.compatible-coupon_box{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.compatible-coupon_right{
    width: 30%;
    height: 100%;
    padding: 16 / @vw 16 / @vw 18 / @vw 12 / @vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: -2 / @vw;
    color: #F2363D;
    font-family: 'SF-Pro';
    .coupon-right_top{
      width: 80 / @vw;
      height: 28 / @vw;
      font-size: 12 / @vw;
      font-style: normal;
      font-weight: 860;
      line-height: normal;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      p{
        overflow: hidden; 
        word-wrap: break-word;
        display: -webkit-box; 
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; 
      }
    }
    .coupon-right_num{
      width: 80 / @vw;
      height: 38 / @vw;
      display: flex;
      align-items: center;
      .coupon-right_discount{
        font-size: 32 / @vw;
        font-weight: 860;
        line-height: normal;
        font-style: normal;
      }
      .coupon-right_symbol{
        font-size: 14 / @vw;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
      }
    }
    .coupon-right_bottom{
      width: 80 / @vw;
      height: 14 / @vw;
      font-size: 12 / @vw;
      font-style: normal;
      font-weight: 1000;
      line-height: normal;
      text-transform: uppercase;
      p{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
      }
    }
  }
.coupon-price_compatible{
    width: 81 / @vw;
    height: 43 / @vw;
    display: flex;
    align-items: center;
    font-size: 15 / @vw;
    font-weight: 860;
    p{
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
}
.compatible-coupon_left{
  width: 217 / @vw;
  height: 78 / @vw;
  padding: 15 / @vw 16 / @vw 12 / @vw 15 / @vw;
  .coupon-left_title{
    width: 217 / @vw;
    height: 14 / @vw;
    color: @sui_color_gray_dark1;
    font-size: 12 / @vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    p{
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
  }
  .coupon-left_desc{
    margin-top: 4  / @vw;
    width: 217 / @vw;
    height: 28 / @vw;
    display: flex;
    align-items: center;
    color: @sui_color_gray_dark2;
    font-size: 12 / @vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
    p{
      word-wrap: break-word;
      overflow: hidden; 
      display: -webkit-box; 
      -webkit-line-clamp: 2; 
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; 
    }
  }
  .coupon-left_btn{
    margin-top: 4  / @vw;
    padding: 7 / @vw 17 / @vw;
    width: 198 / @vw;
    height: 28 / @vw;
    border-radius: 10000 / @vw;
    font-family: 'SF-Pro';
    background: #F2363D;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      color: #FFF;
      font-size: 12 / @vw;
      font-style: normal;
      font-weight: 860;
      line-height: normal;
      text-transform: uppercase;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
  }
}
</style>
