<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.89479 1.5H10.5V6.10521L6.10521 10.5L1.5 5.89479L5.89479 1.5ZM8.25 4.5C7.83579 4.5 7.5 4.16421 7.5 3.75C7.5 3.33579 7.83579 3 8.25 3C8.66421 3 9 3.33579 9 3.75C9 4.16421 8.66421 4.5 8.25 4.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ActivityIcon'
}
</script>

