<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 351 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-inside-1_1183_4067"
      fill="white"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112 0C112 3.31371 109.314 6 106 6C102.686 6 100 3.31371 100 0L8 0C3.58172 0 0 3.58172 0 8L0 76C0 80.4183 3.58173 84 8.00001 84L100.083 84C100.559 81.1623 103.027 79 106 79C108.973 79 111.441 81.1623 111.917 84L343 84C347.418 84 351 80.4183 351 76V8C351 3.58172 347.418 0 343 0L112 0Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112 0C112 3.31371 109.314 6 106 6C102.686 6 100 3.31371 100 0L8 0C3.58172 0 0 3.58172 0 8L0 76C0 80.4183 3.58173 84 8.00001 84L100.083 84C100.559 81.1623 103.027 79 106 79C108.973 79 111.441 81.1623 111.917 84L343 84C347.418 84 351 80.4183 351 76V8C351 3.58172 347.418 0 343 0L112 0Z"
      :fill="fillColor"
    />
    <path
      d="M112 0V-0.5L111.5 -0.5V0L112 0ZM100 0L100.5 0V-0.5L100 -0.5V0ZM8 0V0.5V0ZM8.00001 84V84.5V84ZM100.083 84V84.5H100.506L100.576 84.0827L100.083 84ZM111.917 84L111.424 84.0827L111.494 84.5H111.917V84ZM106 6.5C109.59 6.5 112.5 3.58985 112.5 0L111.5 0C111.5 3.03757 109.038 5.5 106 5.5V6.5ZM99.5 0C99.5 3.58985 102.41 6.5 106 6.5V5.5C102.962 5.5 100.5 3.03757 100.5 0L99.5 0ZM8 0.5L100 0.5V-0.5L8 -0.5V0.5ZM0.5 8C0.5 3.85786 3.85786 0.5 8 0.5V-0.5C3.30558 -0.5 -0.5 3.30558 -0.5 8H0.5ZM0.5 76L0.5 8H-0.5L-0.5 76H0.5ZM8.00001 83.5C3.85787 83.5 0.5 80.1421 0.5 76H-0.5C-0.5 80.6944 3.30559 84.5 8.00001 84.5V83.5ZM100.083 83.5L8.00001 83.5V84.5L100.083 84.5V83.5ZM100.576 84.0827C101.012 81.4819 103.275 79.5 106 79.5V78.5C102.779 78.5 100.106 80.8427 99.5899 83.9173L100.576 84.0827ZM106 79.5C108.725 79.5 110.988 81.4819 111.424 84.0827L112.41 83.9173C111.894 80.8427 109.221 78.5 106 78.5V79.5ZM343 83.5L111.917 83.5V84.5L343 84.5V83.5ZM350.5 76C350.5 80.1421 347.142 83.5 343 83.5V84.5C347.694 84.5 351.5 80.6944 351.5 76H350.5ZM350.5 8V76H351.5V8H350.5ZM343 0.5C347.142 0.5 350.5 3.85787 350.5 8H351.5C351.5 3.30558 347.694 -0.5 343 -0.5V0.5ZM112 0.5L343 0.5V-0.5L112 -0.5V0.5Z"
      :fill="borderColor"
      mask="url(#path-1-inside-1_1183_4067)"
    />
    <line
      :opacity="lineColor == '#FF3F27' ? '0.4' : '1'"
      x1="106.25"
      y1="14.25"
      x2="106.25"
      y2="73.75"
      :stroke="lineColor"
      :stroke-opacity="lineColor == '#FF3F27' ? '0.4' : '1'"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-dasharray="3.48 3.48"
    />
  </svg>
</template>

<script>
export default {
  name: 'FirstCouponBg',
  props: {
    fillColor: { type: String, default: '#FFF6F3' },
    borderColor: { type: String, default: '#FFE2CF' },
    lineColor: { type: String, default: '#FF3F27' }
  },
}
</script>

