// 通过webpack进行混淆处理
import GeeTest from 'public/src/pages/common/RiskCommon/libs/geetest/sdk.js'
import { InitFtoken } from 'public/src/pages/common/ftoken/index.js'
// 登录注册模块初始化极验sdk，使用前请询问
// 服务端渲染不执行
if (typeof window === 'object') {

  if (!window.initFtokenCb) {
    // 指纹生成后的回调
    window.initFtokenCb = []

    if (!window.UserGTInstance) window.UserGTInstance = new GeeTest()

    const getToken = () => {
      InitFtoken(() => {
        window.UserGTInstance.getGtAbtResult(() => {
          window.initFtokenCb.forEach((cb) => {
            cb()
          })
        })
      })
    }
    setTimeout(() => {
      getToken()
    }, 0)
  }
}
export default {}
