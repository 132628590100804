<template>
   <s-drawer
    :visible="newUIStates.showType == 'encryptionPhone'"
    type="full"
    direction="rtl"
    :no-header="true"
    :append-to-body="true"
    :immediately-render="true"
  >
    <PhoneLoginPage />
  </s-drawer>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import PhoneLoginPage from '@login/components/MultiAccount/PhoneLoginPage.vue'

const store = useStore()

const newUIStates = computed(() => store.state.login.newUIStates)

;
</script>
<script>
  export default {
    name: 'EncryptionPhone'
  } 
</script>