
import { mapMutations, mapState } from 'vuex'
import { filterAreaCodeFn } from '@login/utils/phoneUtil'
import { showRegisterSuccessPopFormat } from '@login/utils/common.js'
const { FILTER_AREACODE_LIST } = gbCommonInfo

export default {
  computed: {
    ...mapState('login', ['commonAbt', 'filterAreaCodeTip', 'loginPopConfig']),
  },
  methods: {
    ...mapMutations('login', ['assignState',]),
    showInputTips(tip, msg, show = true) {
      if (!tip) return
      tip.show = show
      tip.txt = msg
    },
    setShowPop(){
      const { from, bi } = this.loginPopConfig || {}
      const loginFrom = bi || from
      const isSwitch = showRegisterSuccessPopFormat(loginFrom)
      return isSwitch
    },
    handleFilterAreaCode (area_code, alias) {
      const PhoneZeroFiltered = this.commonAbt.PhoneZeroFiltered
      const aliasRes = filterAreaCodeFn(PhoneZeroFiltered, FILTER_AREACODE_LIST, area_code, alias)
      if(aliasRes != alias){
        this.assignState({
          filterAreaCodeTip: {
            show: true,
            area_code,
            aliasRes
          }
        })
      }
      return aliasRes
    },
    /**
     * @description 手机号注册勾选订阅调用订阅接口
     * @date 2021-09-22 16:46
     * @param {object} params
     * 1:用户注册页、2:专题系统、3:PC页脚订阅、4:游戏系统、5:大会员会员管理、6:弹框注册、7:订阅中心、8:用户中台、9:第三方邮件服务、10:短信订阅页、11:支付成功页、12:订单详情页、13:绑定手机号界面
     * @param {number|string} scene_type - 订阅场景
     */
    async handlePostAddSubscribe(params = {}, scene_type = '1') {
      const { postAddSubscribe } = await import('public/src/pages/common/biz_helper/subscribe')
      postAddSubscribe({
        subscribe_type: '2',
        alias: params.alias,
        scene_type: scene_type || '1',
        area_code: params.area_code,
        country: params.area_abbr,
        verify: '1'
      })
    },
  },
}
