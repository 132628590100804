import { computed, nextTick } from 'vue'
import { useStore, useEventBus } from '@login/hooks/utils'
import { getQueryString, parseQueryString } from '@shein/common-function'
import { getSupportCountry } from '@login/utils/formatUtils.js'
import { getSiteToDefaultCountry, phoneCodeCache, getFuzzyDefaultLocation } from '@login/util'
import { getCartNum } from '@login/service'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default function useLoadMainHook () {

  const store = useStore()
  const evt = useEventBus()

  const instancename = computed(() => store.state.login.instancename)

  const handleForget = ({ email = '', from = '', type = 'email', phone = '' } = {}) => {
    store.commit('login/setForgetPassword', { show: true, email, from, type, phone })
    evt.$emit('update-forget-password-info', {
      email,
      phone,
      instancename: instancename,
    })
  }

  const setSiteUidDefaultCode = (all) => {
    if (all.length == 0) return
    const supportCountry = getSupportCountry(all)
    const countryCode = getFuzzyDefaultLocation(supportCountry)
    let supportCountryValues = supportCountry.map(i => i.value)
    if(countryCode) { // 存在默认国家则将它优先级设置为最高
      const target = countryCode.value
      const index = supportCountryValues.indexOf(target)
      if (index !== -1) {
        supportCountryValues.splice(index, 1)
        supportCountryValues.unshift(target)
      }
    }
    if (typeof window != 'undefined') {
      window.localStorage.setItem('SITEUID_COUNTRYS', JSON.stringify(supportCountryValues))
    }
  }

  const setDefaultAreaCode = (countryCodes) => {
    const { phone_code = '' } = parseQueryString(location.search)
    // 从切站带过来的URL phone_code 缓存
    if (phone_code) {
      const { code: targetCode, value } = phoneCodeCache.parseStr(phone_code)
      const target = countryCodes.find((v) => v.value == targetCode)
      if (target) {
        phoneCodeCache.setValue(value)
        store.commit('login/setAreaCode', { ...target })
        return
      }
    }
    const cacheCode = phoneCodeCache.get()
    if (cacheCode) {
      // 缓存code
      const target = countryCodes.find((v) => v.value == cacheCode)
      if (target) {
        store.commit('login/setAreaCode', { ...target })
        return
      }
    }
    let defaultArea = getSiteToDefaultCountry(countryCodes)
    store.commit('login/setAreaCode', defaultArea)
  }

  const formatSupportCountry = (list) => {
    const supportCountry = getSupportCountry(list)
    store.commit('login/setDefaultLocation', supportCountry.concat?.() || [])
    filterDefaultLocation(supportCountry)
  }

  const filterDefaultLocation = (supportCountry) => {
    if (supportCountry.length == 0) return
    const { countryCode } = parseQueryString(location.search)
    const queryCountry = supportCountry.find((v) => v.value == countryCode)
    if (queryCountry) {
      store.commit('login/setDefaultLocation', { ...queryCountry })
      return
    }
    const defaultLocation = getSiteToDefaultCountry(supportCountry)
    store.commit('login/setDefaultLocation', { ...defaultLocation }) 
  }

  const getCartInfo = () => {
    const redirection = getQueryString({ key: 'redirection' })
    if (redirection && redirection.indexOf('checkout') >= 0) {
      getCartNum((res) => {
        evt.$emit('request-cart-number-success', res)
      })
    }
  }

  const handleShowForgotPassword = (from) => {
    if(typeof window === 'undefined') return false
    return from === 'resetPassword' && !window.isLoginPageAsCompnent
  }

  const exposeEvents = () => {
    const daEventExpose = daEventCenter.getExposeInstance()
    nextTick(() => {
      daEventExpose.subscribe({
        once: false,
        keycode:
          'page-login-expose-events`2-8-12|2-8-19|2-8-28|2-8-35|2-8-50|2-8-30|2-8-52|2-8-54|2-8-42|2-8-60|2-8-62|2-8-64|2-8-65|2-8-67|2-8-70|2-8-72|2-8-96',
      })
      daEventExpose.subscribe({
        once: true,
        keycode: 'page-login-pop-expose-events`2-8-60|2-8-62',
      })
      daEventExpose.subscribe({
        once: false,
        keycode:
          'page-login-mergePhonePop-signup`2-8-35|2-8-60|2-8-62|2-8-67|2-8-54',
      })
      daEventExpose.subscribe({
        once: false,
        keycode:
          'page-login-mergePhonePop-login`2-8-35|2-8-60|2-8-62|2-8-67|2-8-54',
      })
      daEventExpose.subscribe({
        once: false,
        keycode: 'page-login-NewemailPannel`2-8-60|2-8-62',
      })
      daEventExpose.subscribe({
        once: false,
        keycode: 'page-login-NewphonePannel`2-8-60|2-8-62',
      })
      // page-login-mergePhonePop-signup
    })  
  }

  return { handleForget, setSiteUidDefaultCode, setDefaultAreaCode, formatSupportCountry, getCartInfo, handleShowForgotPassword, exposeEvents }
}
