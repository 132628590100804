
<template>
  <div class="recommend-prouduct_container">
    <div class="recommend-prouduct_title">
      <p>{{ langText?.SHEIN_KEY_PWA_29162 }}</p>
    </div>
    <ul
      v-if="productList"
      class="recommend-prouduct_content"
    >
      <li
        v-for="item in productList"
        :key="item.goods_id"
        class="recommend-prouduct_item"
        @click="handleToAddOnItem(item.goods_id)"
      >
        <img
          class="recommend-prouduct_img"
          :src="item.goods_img"
          alt=""
        />
        <template v-if="!isLongCurrency">
          <div
            v-if="item.salePrice"
            class="recommend-prouduct_price"
          >
            <span class="recommend-prouduct_amount">
              {{ item.salePrice.amountWithSymbol }}
            </span>
            <span
              v-if="item.unit_discount > 0"
              class="recommend-prouduct_discount"
            >-{{ item.unit_discount }}%</span>
          </div>
        </template>
        <template v-if="isLongCurrency">
          <div class="compatible_price">
            <p class="compatible-price_top">
              {{ item.salePrice.amountWithSymbol }}
            </p>
            <p
              v-if="item.unit_discount > 0"
              class="compatible-price_bottom"
            >
              -{{ item.unit_discount }}%
            </p>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { clickBenefitSection } from '@login/utils/loginAnalysis'
const { currency, REGISTER_LONG_CURRENCY } = gbCommonInfo

const props = defineProps({
  langText: {
    type: Object,
    default: () => {}
  },
  productList: {
    type: Array,
    default: () => []
  },
  configData: {
    type: Object,
    default: () => {}
  }
})

const isLongCurrency = computed(() => {
  if(REGISTER_LONG_CURRENCY.includes(currency)) return true
  return false
})

const handleToAddOnItem = async (goodsId) => {
  const AddOnItem =  (await import('public/src/pages/common/add-on/index.js')).default
  const productData = props.configData?.productList
  const productList = productData.map(item => item.goods_id)
  const index = productList.indexOf(goodsId)
  if(index !== -1) {
    productList.splice(index, 1)
    productList.unshift(goodsId)
  }
  AddOnItem.open({
    props: {
      type: 'coupon',
      coupon: props.configData?.couponData?.couponCode,
      queryInfo: {
        addOnType: 2, // 问产品拿
        sceneId: 152, // 推荐场景id
        goodsIds: [],
        cateIds: [],
        direct_tag: props.configData?.couponData?.direct_tag,
        return_tag: props.configData?.couponData?.return_tag,
        adp: productList, 
      },
      saInfo: {
        // 主要用于埋点
        activity_from: 'registration_success_add',
        state: 'registration_success_add'
      }
    },
    on: {
      close () {
      }
    }
  })
  clickBenefitSection(index + 1, 'Regisger_Popup_A')
}
 

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'RecommendProuduct'
}
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.recommend-prouduct_container{
  padding: 12 / @vw 0;
  margin-top: 8 / @vw;
  width: 100%;
  height: 144 / @vw;
  border-radius: 8 / @vw;
  border: 1 / @vw solid #FFF;
  background: linear-gradient(186deg, #F8D3FA -1.55%, #FFF 20.96%);
  box-shadow: 0 1.822/@vw 1.822/@vw 0 rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2.277158737182617/@vw);
}
.recommend-prouduct_title{
  width: 100%;
  height: 14 / @vw;
  color: #222;
  font-size: 12 / @vw;
  font-weight: 700;
  padding-left: 12 / @vw;
  p{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
}
.recommend-prouduct_content{
  margin-top: 8 / @vw;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  display: -webkit-flex;
  -webkit-overflow-scrolling:touch;
  .recommend-prouduct_item{
    width: 80 / @vw;
    // height: 98 / @vw;
    margin-right:8 / @vw;
    &:first-child {
      margin-left:8 / @vw;
    }
    .recommend-prouduct_img{
      width: 80 / @vw;
      height: 80 / @vw;
      border-radius: 2 / @vw;
  }
    .recommend-prouduct_price{
      margin-top: 4 / @vw;
      width: 100%;
      height: 14 / @vw;
      display: flex;
      align-items: center;
      color: #F2363D;
    }
    .compatible_price{
      color: #F2363D;
    }
  }
}
.recommend-prouduct_amount{
  font-size: 12 / @vw;
  font-family: 'SF-Pro';
  font-weight: 700;
  font-style: normal;
  line-height: normal;
}
.recommend-prouduct_discount{
  margin-left: 2 / @vw;
  width: 26 / @vw;
  height: 12 / @vw;
  line-height: 12 / @vw;
  border-radius: 2 / @vw;
  text-align: center;
  font-size: 10 / @vw;
  border: 0.455 / @vw solid #F2363D;
}
.compatible-price_top{
  width: 100%;
  height: 14 / @vw;
  display: flex;
  align-items: center;
  font-size: 12 / @vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: 'SF-Pro';
}
.compatible-price_bottom{
  margin-top: 3 / @vw;
  width: 26 / @vw;
  height: 12 / @vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10 / @vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2 / @vw;
  border: 0.455 / @vw solid #F2363D;
}
</style>
