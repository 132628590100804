<template>
  <div>
  </div>
</template>

<script>
import ThirdPartLogin from '@login/mixin/third_part_login.js'
export default {
  name: 'ThirdLoginFunction',
  mixins: [ThirdPartLogin]
}
</script>
