import MainPage from '../main.vue'
import { mapMutations, mapState } from 'vuex'
import ClientOnly from 'vue-client-only'
import { initNeedsLibs } from '../common/index'
import { initThirdLoginSdk } from '@login/common/index' 
import analysisIndex from '../analysis/index'
import { getSessionStorage } from '@shein/common-function'
import { getCouponsData } from 'public/src/pages/login/services/newUer.js'
import { exposeScenesabt } from 'public/src/pages/login/components/PointRetainModal/util.js'
import { Metric_Login_Page_Expose, Metric_Login_Detail_Total } from '@login/utils/monitorLog.js'
import { saSend } from '@login/analysis/util'
const { IS_RW } = gbCommonInfo

const { sensorsSend } = analysisIndex


export default {
  components: {
    MainPage,
    ClientOnly,
    LoginCommonDialogs: () => import(/* webpackChunkName: "login_commonDialogs" */ /* @vite-ignore*/'@login/views/LoginCommonDialogs/index.vue'),
  },
  data: () => ({
    isHideMainPop: false,
    popConfig: {},
    isShowLoading: false,
    isloadSDK: false,
  }),
  computed: {
    ...mapState('login', ['showIndex', 'langText', 'isAsComponent', 'relatedAcc', 'commonAbt', 'switchAccount', 'publicLocalData']),
  },
  watch: {
    showIndex(v) {
      if (v) {
        sensorsSend('2-8-1')
        Metric_Login_Page_Expose({ page_type: 'pop', sub_site: this.publicLocalData?.SiteUID })
        if (!this.isloadSDK) {
          setTimeout(() => {
            this.assignState({
              loadComponent: true
            })
            initNeedsLibs()
            this.isloadSDK = true
          }, 200)
        }
      }
    },
    'switchAccount.show'(v){
      if(v){
        saSend('2-8-105', { scene: 'login', type: this.switchAccount.showType == 7 ? 'email' : 'phone', acc: this.relatedAcc })
        Metric_Login_Detail_Total({ account_type: this.switchAccount.showType == 7 ? 'email' : 'phone', sub_site: this.publicLocalData?.SiteUID || '' })
        this.assignState({
          loadComponent: true
        })
        setTimeout(() => {
          initNeedsLibs()
          this.$refs.mainPage?.exposeEvents()
          this.isloadSDK = true
        }, 200)
      }
    }
  },
  mounted() {
    this.$evt.$on('event-login-page-component', (v) => this.showPop(v))
    // 注册loading事件，更新loading modal
    this.$evt.$on('event-loading', (v) => {
      this.isShowLoading = v
    })
    window.signupTimeTag = null
  },
  methods: {
    ...mapMutations('login', [
      'setRelatedAcc',
      'setShowIndex',
      'setIsAsComponent',
      'setInstanceName',
      'setLoginPopConfig',
      'setnewUIStates',
      'setAreaCode',
      'assignState',
      'setRetainModalConfig',
      'setLoginButtonPoint',
      'setSwitchAccount'
    ]),
    async showPop(val) {
      const {
        show,
        toRobot = false,
        isHideMainPop,
        innerPopConfig,
        action,
        action_val,
        switchAccountVal,
        instance = '',
        signupAccount = {},
        signupAreaCode = {},
        isSwitchAccount = false,
        retainData = {}
      } = val
      this.setIsAsComponent(true)
      this.setInstanceName(instance)
      this.popConfig = val
      this.setLoginPopConfig(val)
      this.assignState({
        isShowRetainModal: false,
      })
      if (window.JOURNEY_BRANCH) {
        window.JOURNEY_BRANCH.loginDialogShow = show
        this.$nextTick(() => {
          $('.main-login-container .c-aside.c-aside-full').css(
            'margin-top',
            window.JOURNEY_BRANCH.isShow ? $('.journey-iframe').height() + 'px' : ''
          )
          $('.journey-iframe').css('z-index', '10000')
        })
      }
      if(action == 'switch_account'){
        const { accountType, accountAlias, accountSecurity, area_code, area_abbr } = switchAccountVal || {}
        const thirdLoginType = [11, 12, 13, 15, 16, 17]
        if(thirdLoginType?.includes(accountType)){
          await initThirdLoginSdk(accountType)
          setTimeout(() => {
            this.$refs?.loginCommonDialogsRef?.handleThirdLoginFun(accountType)
          }, 1000)
          return
        }
        if(accountType == 7 || accountType == 8) {
          this.setSwitchAccount({
            show: true,
            showType: accountType == 7 ? 'email' : 'phone',
            accountAlias,
            accountType,
            accountSecurity,
            area_code,
            area_abbr
          })
        }
        return
      }
      if (action == 'relation_account') {
        this.showRelationPop(action_val)
        return
      }
      // 只要内层弹窗逻辑，不需要主登录逻辑
      this.isHideMainPop = !!isHideMainPop
      if (!!isHideMainPop) {
        this.setShowIndex(false)
        this.assignState({
          showAreadCode: false,
        })
        this.$nextTick(() => {
          this.innerPopChange(innerPopConfig || {})
        })
      }
      if (!IS_RW) {
        this.setRetainConfig(retainData)
        this.setSubscriptConfig(retainData)
      } 
      // 快速注册弹框带入账号
      if (signupAccount && signupAccount.alias != '') {
        if (signupAccount.isShowAreaCode && signupAccount.alias != '') {
          this.setnewUIStates({
            mode: 'phone',
            alias: signupAccount.alias || '',
          })
          this.assignState({
            showAreadCode: true,
          })
          this.setAreaCode(signupAreaCode)
        } else {
          this.setnewUIStates({
            mode: 'email',
            alias: signupAccount.alias || '',
          })
        }
      }
      this.$nextTick(() => {
        this.setShowIndex(show)
        this.setnewUIStates({ isSwitchAccount })
        this.dealWithFinal(show)
        if (this.$refs?.mainPage) {
          this.$refs.mainPage.toRobot = toRobot
        }
      })
    },
    updateSignUp(info = {}) {
      this.$refs.mainPage.updateSignUp(info)
    },
    showBindEmail(data = {}) {
      this.$refs.LoginCommonDialogs?.showBindEmail(data)
    },
    innerPopChange(config) {
      const { type, data } = config
      // 邮箱去重
      if (type == 'emailDeduplication') {
        this.showBindEmail(data)
        return
      }
    },
    dealWithFinal(show) {
      // 全局关闭的时候把关联账号弹窗也关闭
      if (show === false && this.relatedAcc.show) this.setRelatedAcc({ show: false })
    },
    showRelationPop(v) {
      sensorsSend('2-8-79', { related_type: v.abt, bi: v.bi })
      let third_parts = new Set() // 支持3方登陆的类型
      v.list.forEach((v1) => {
        if (v1.is_third) third_parts.add(v1.register_from)
      })
      this.setnewUIStates({ isRelation: true })
      this.setRelatedAcc({ show: true, ...v, third_parts: [...third_parts] })
    },
    setRetainConfig(retainModalData) {
      const cacheRetainModal = getSessionStorage('cacheRetainModal') || []
      if (!cacheRetainModal.includes('newUser')) {
        cacheRetainModal.push('newUser')
      }
      const LoginRegisterRetianPops = this.commonAbt?.LoginRegisterRetianPops?.split(',') || []
      const intersection = LoginRegisterRetianPops.filter((value) =>
        cacheRetainModal.includes(value)
      )
      intersection.forEach((v) => {
        const index = LoginRegisterRetianPops.indexOf(v)
        if (index > -1) {
          LoginRegisterRetianPops.splice(index, 1)
        }
      })
      if (LoginRegisterRetianPops.length == 0) return
      const retainData = retainModalData || {}
      if (
        LoginRegisterRetianPops.includes('coupons') &&
        retainData &&
        retainData?.coupon_codes?.length > 0
      ) {
        this.getCouponList(retainData.coupon_codes)
        return
      }
      const newLoginRegisterRetianPops = LoginRegisterRetianPops.filter((v) => v != 'newUser')
      const newArrList = newLoginRegisterRetianPops.filter((v) => v != 'coupons')
      const firstPop = newArrList?.[0]
      this.setRetainCommonConfig(firstPop, newArrList, retainData)
    },
    setSubscriptConfig(incentiveData){
      const loginIncentive = this.commonAbt?.LoginPageIncentive
      if(loginIncentive.coupons == 'on' && incentiveData && incentiveData?.coupon_codes?.length > 0){
        this.getCouponList(incentiveData.coupon_codes)
        return
      }
      if(loginIncentive.cart && loginIncentive.cart != 'off'){
        const cartIncentive = loginIncentive?.cart?.split(',') || []
        const firstIncentive = cartIncentive?.[0]
        this.setSubscriptCommonConfig(firstIncentive, cartIncentive, incentiveData)
      }
    },
    setRetainCommonConfig(firstPop, abtList, configData) {
      if (firstPop == 'promotion') {
        if (firstPop == 'promotion' && !configData.promotion_price) {
          const freeShippingList = abtList.filter((v) => v != 'promotion')
          if (freeShippingList.includes('freeShipping') && configData?.free_shipping == true) {
            exposeScenesabt('freeShipping')
            const obj = {
              type: 'freeShipping',
              from: ''
            }
           
            this.setRetainModalConfig(obj)
          }
          return
        }
        if (firstPop == 'promotion' && configData.promotion_price) {
          exposeScenesabt('promotion')
          const obj = {
            type: 'promotion',
            from: '',
            promotion_price: configData.promotion_price
          }
         
          this.setRetainModalConfig(obj)
          return
        }
      }
      if (firstPop == 'freeShipping') {
        if (firstPop == 'freeShipping' && configData?.free_shipping != true) {
          const promotionList = abtList.filter((v) => v != 'freeShipping')
          if (promotionList.includes('promotion') && configData?.promotion_price) {
            exposeScenesabt('promotion')
            const obj = {
              type: 'promotion',
              from: '',
              promotion_price: configData.promotion_price,
            }
            this.setRetainModalConfig(obj)
          }
          return
        }

        if (firstPop == 'freeShipping' && configData?.free_shipping == true) {
          exposeScenesabt('freeShipping')
          const obj = {
            type: 'freeShipping',
            from: ''
          }
          this.setRetainModalConfig(obj)
          return
        }
      }
    },
    setSubscriptCommonConfig(firstPop, abtList, configData) {
      if (firstPop == 'promotion') {
        if (firstPop == 'promotion' && !configData.promotion_price) {
          const freeShippingList = abtList.filter((v) => v != 'promotion')
          if (freeShippingList.includes('freeshipping') && configData?.free_shipping == true) {
           
            const obj = {
              type: 'freeShipping',
              from: ''
            }
            this.setLoginButtonPoint(obj)
          }
          return
        }
        if (firstPop == 'promotion' && configData.promotion_price) {
          const obj = {
            type: 'promotion',
            from: '',
            promotion_price: configData.promotion_price
          }
          this.setLoginButtonPoint(obj)
         
          return
        }
      }
      if (firstPop == 'freeshipping') {
        if (firstPop == 'freeshipping' && configData?.free_shipping != true) {
          const promotionList = abtList.filter((v) => v != 'freeshipping')
          if (promotionList.includes('promotion') && configData?.promotion_price) {
            const obj = {
              type: 'promotion',
              from: '',
              promotion_price: configData.promotion_price,
            }
            this.setLoginButtonPoint(obj)
           
          }
          return
        }

        if (firstPop == 'freeshipping' && configData?.free_shipping == true) {
          const obj = {
            type: 'freeShipping',
            from: ''
          }
          this.setLoginButtonPoint(obj)
          
          return
        }
      }
    },
    async getCouponList (couponCodes) {
      const loginIncentive = this.commonAbt?.LoginPageIncentive
      const LoginRegisterRetianPops = this.commonAbt?.LoginRegisterRetianPops?.split(',') || []
      const data = await getCouponsData({ coupon_codes: couponCodes })
      if (data.code == 0) {
        const { info = {} } = data || {}
        exposeScenesabt('coupons')
        const obj = {
          type: 'coupons',
          from: '',
          couponData: info
        }
        if(loginIncentive.coupons == 'on'){
          this.setLoginButtonPoint(obj)
        }
        if(LoginRegisterRetianPops.includes('coupons')){
          this.setRetainModalConfig(obj)
        }
      }
    },
  },
}
