<template>
  <SDialog
    class="retainModal-container"
    :visible="showPointRetainModal"
    :show-close="true"
    :append-to-body="true"
    :close-color="'#ffffff'"
    :close-on-click-modal="false"
    @close="handleCloseRetain('close')"
  >
    <template slot="top">
      <ComponentType :config="RetainModalConfig" />
    </template>
    <div class="retainModal-content">
      <div class="retainModal-content_title">
        {{ modalTitle }}
      </div>
      <div>
        {{ modalDesc }}
      </div>
    </div>
    <template slot="footer">
      <SButton
        :type="['primary']"
        width="100%"
        class="retainModal-footer_btn"
        @click="handleConfirm('leave')"
      >
        {{ modalBtnText }}
      </SButton>
      <div
        class="retainModal-footer_back"
        @click.stop="handleReturnBack('back')"
      >
        {{ langText.SHEIN_KEY_PWA_27188 }}
      </div>
    </template> 
  </SDialog>
</template>

<script setup>
import { onMounted, computed, watch } from 'vue'
import ComponentType from './components/ComponentType.vue'
import { useStore } from '@login/hooks/utils.js'
import { getSessionStorage, setSessionStorage } from '@shein/common-function'
import { formatDesc, clickRetainTracking, exposeRetainTracking } from './util'

const store = useStore()

const showPointRetainModal = computed(() => store.state.login.showPointRetainModal)

const RetainModalConfig = computed(() => store.state.login.RetainModalConfig)

const langText = computed(() => store.state.login.langText)

const registerMode = computed(() => store.state.login.newUIStates.mode)

const modalTitle = computed(() => {
  const type = RetainModalConfig.value.type
  return type == 'freeShipping' ? langText.value.SHEIN_KEY_PWA_27139 : ''
})

const modalDesc = computed(() => {
  const type = RetainModalConfig.value.type
  return formatDesc(type, langText.value)
})

const modalBtnText = computed(() => {
  const from = RetainModalConfig.value.from
  return (from == 'register' ? langText.value.SHEIN_KEY_PWA_27193 : langText.value.SHEIN_KEY_PWA_27192) || langText.value.SHEIN_KEY_PWA_27192
})

watch(showPointRetainModal, (val) => {
  if (val) {
    const type = RetainModalConfig.value.type
    exposeRetainTracking(type)
  }
}, { immediate: true })

const handleCloseRetain = (from) => {
  if (showPointRetainModal.value == false) return
  const cacheRetainModal = getSessionStorage('cacheRetainModal') || []
  if (RetainModalConfig.value.type != '') {
    cacheRetainModal.push(RetainModalConfig.value.type)
  }
  setSessionStorage({ key: 'cacheRetainModal', value: cacheRetainModal })
  store.commit('login/setPointRetainModal', false)
  const type = RetainModalConfig.value.type
  clickRetainTracking(type, from)
}

const handleConfirm = (from) => {
  handleCloseRetain(from)
}

const handleReturnBack = (from) => {
  handleCloseRetain(from)
  if (RetainModalConfig.value.from == 'register') {
    if(registerMode.value == 'email'){
      store.commit('login/assignState', { closeRetainModal: 'email' })
    }
    if(registerMode.value == 'phone'){
      store.commit('login/assignState', { closeRetainModal: 'phone' })
    }
    return
  }
  store.commit('login/setShowIndex', false)
}

onMounted(()=> {
  // eslint-disable-next-line semi
});
</script>
<script>
export default {
  name: 'PointRetainModal',
}
</script>

<style lang="less" scoped>
/* stylelint-disable declaration-no-important */
.retainModal-container{
 /deep/.S-dialog__normal{
    width: 285px !important;
    max-width: 285px !important;
  }
// stylelint-disable-next-line
/deep/.S-dialog__top img{
    padding-bottom: 0;
  }
/deep/.S-dialog__body{
  margin-top: -8px;
}
}
.retainModal-content{
  // margin-top: -8px; 
  text-align: center;
  color: #000000;
  &_title{
    font-size: 16px;
    font-weight: 700;
    margin: 12px auto; 
  }
}
.retainModal-footer_back{
  margin-top: 12px;
  font-size: 14px;
  text-align: center;
  color: @sui_color_link;
}
.retainModal-footer_btn{
  height: 36px;
  line-height: 36px;
}
</style>
