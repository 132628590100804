<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.58959 4.72238C5.77735 4.6956 6.20905 4.7331 6.38609 4.7921C6.61397 4.87394 6.83925 5.071 6.92639 5.27617C7.0752 5.61941 7.03631 5.96934 6.72926 7.0567C6.48814 7.91608 6.42918 8.15738 6.42075 8.38161L6.41961 8.44573C6.41961 8.671 6.4759 8.80109 6.60324 8.86008C6.73869 8.92444 7.10205 8.91906 7.32463 8.84934C7.42251 8.82118 7.50827 8.79841 7.51363 8.80377C7.519 8.80908 7.50021 8.90165 7.47076 9.00889L7.41981 9.20064L7.19454 9.29046C6.55767 9.54651 6.17821 9.61761 5.77199 9.55456C5.38848 9.49558 5.12439 9.32667 4.96883 9.03971C4.90447 8.91906 4.88438 8.84797 4.87633 8.65628C4.85892 8.32645 4.90179 8.11053 5.15521 7.21087C5.41134 6.29512 5.46227 6.0699 5.46227 5.83389C5.46227 5.60062 5.40596 5.47591 5.27588 5.4223C5.12976 5.36062 4.7865 5.36867 4.56391 5.43571L4.38696 5.49202L4.40438 5.38741C4.42743 5.24725 4.43758 5.17272 4.47708 5.12031C4.52487 5.05693 4.61559 5.02592 4.82402 4.95033C5.15384 4.82967 5.40996 4.75322 5.58959 4.72238Z"
      fill="#959595"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.39145 2.27682C6.57509 2.21247 6.86471 2.21783 7.0511 2.28487C7.25354 2.36128 7.44261 2.53293 7.5378 2.72999C7.59946 2.86008 7.61419 2.92444 7.61419 3.08796C7.61151 3.34139 7.5351 3.50768 7.33261 3.69001C6.76685 4.20891 5.80015 3.83346 5.80015 3.10139C5.80015 2.99684 5.81357 2.87081 5.83367 2.81713C5.91544 2.57713 6.13534 2.36396 6.39145 2.27682Z"
      fill="#959595"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6ZM11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6Z"
      fill="#959595"
    />
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon'
}
</script>
