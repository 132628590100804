<template>
  <div class="header-desc_container">
    <div
      v-if="headerData?.registerText"
      class="header-desc_email"
    >
      <span v-html="headerData?.registerText"></span>
      <!-- <i class="sui_icon_info_12px_2 suiiconfont"></i> -->
      <div
        v-show="isShowInfoIcon"
        class="header-desc_box"
        @click="handleOpenHelpPop"
      >
        <InfoIconVue class="header-desc_icon" />
      </div>
    </div>
    <HelpPagePopup />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import InfoIconVue from './Icon/InfoIcon.vue'
import HelpPagePopup from '../HelpPagePopup'
import { useStore } from '@login/hooks/utils.js'

const props = defineProps({
  headerData: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()

const isShowInfoIcon = computed(() => {
  if(props.headerData.tipsType) return true
  return false
} )

const handleOpenHelpPop = () => {
  const configData = {
    isShow: true,
    registerType: props.headerData.registerType,
    tipsType: props.headerData.tipsType
  }
  store.commit('login/setHelpPageConfig', configData)
}

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'HeaderDesc'
}
</script>

<style lang="less">
@vw: 375/100vw;
.header-desc_container{
  margin-top: 12 / @vw;
}
.header-desc_email{
  color: #666666;
  font-size: 12 / @vw;
  .point-text{
    color: @sui_color_micro_emphasis;
  }
  .header-desc_box{
    display: inline-block;
    transform: translate(2px, 2px);
    .header-desc_icon{
      width: 12 / @vw;
      height: 12 / @vw;
    }
  }
}
</style>
