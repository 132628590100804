import { useStore, useEventBus } from '@login/hooks/utils'
import { filterZeroFormat } from '@login/utils/phoneUtil'
import { aliasLocationSer, phoneCheckSer } from '@login/service'
import { saSend } from '@login/analysis/util'
import { urlRedirection, sleep } from '@login/util'
import { exposeScenesabt } from '@login/components/PointRetainModal/util.js'
import { getSessionStorage } from '@shein/common-function'
import { Metric_Login_Continue_Click, Metric_Login_Detail_Total, Metric_Register_Detail_Total } from '@login/utils/monitorLog.js'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
const { IS_RW, FILTER_AREACODE_LIST } = gbCommonInfo

export default function useLocationHook ({ aliasTip, areaCode, langText, newUIStates, preloadData, commonAbt, publicLocalData, accountList, isAsComponent, relatedAcc, filterAreaCodeTip, handleFilterAreaCode, showInputTips, handleSendCode }) {
  
  const store = useStore()
  const evt = useEventBus()

  const setSignUpRetainModal = (type) => {
    if(accountList.value.length) return
    if (IS_RW) return
    const preData = preloadData.value || {}
    const isNewUser = commonAbt.value?.NewUserRights || {}
    if (!isNewUser) return
    if (!preData?.cccNewUserRigths?.data) return
    const LoginRegisterRetianPops = commonAbt.value?.LoginRegisterRetianPops
    const result = LoginRegisterRetianPops.split(',')
    if (!result.includes('newUser')) return
    const cacheRetainModal = getSessionStorage('cacheRetainModal') || []
    if (cacheRetainModal.includes('newUser')) return
    if (type == 'signup') {
      exposeScenesabt('newUser')
      const obj = {
        type: 'newUser',
        from: 'register',
      }
      store.commit('login/setRetainModalConfig', obj)
    }
  }

  const handleUserLocation = async (smsType) => {
    const { code: area_code, abbr: area_abbr } = areaCode.value || {}
    const { alias, mode } = newUIStates.value
    let resAlias = alias
    if (mode == 'phone') {
      resAlias = handleFilterAreaCode(areaCode.value, alias)
    }
    if (resAlias == '' && mode == 'phone') {
      showInputTips(aliasTip.value, langText.value.SHEIN_KEY_PWA_18690)
      return
    }
    const params = {
      alias: resAlias,
      area_code,
      area_abbr,
      alias_type: mode == 'email' ? 1 : 2,
      page_source: 'continue',
    }
    await requestUserLocation(params, smsType)
  }

  const requestUserLocation = async (params, smsType) => {
    const { mode } = newUIStates.value
    evt.$emit('event-loading', true)
    const result = await geetestChallenge(aliasLocationSer, params, 'user_location')
    if (!result) {
      evt.$emit('event-loading', false)
      return
    }
    const { apiResData = {}, status } = result
    if (status == 'error') {
      evt.$emit('event-loading', false)
      return
    }
    if (status == 'close') {
      evt.$emit('event-loading', false)
      return
    }
    const { code, info, tips } = apiResData
    const type = info?.is_existed == 1 ? 'login' : 'signup'
    const subscribe_status = info?.subscribe_status || ''
    const filterParams = {
      abt: commonAbt.value.PhoneZeroFiltered,
      filterList: FILTER_AREACODE_LIST,
      areaCode: areaCode.value,
      alias: newUIStates.value.alias,
      isFilter: filterAreaCodeTip.value.show,
    }
    const is_zero_filtered = filterZeroFormat(filterParams)
    saSend('2-8-100', { code, scene: code == 0 ? type : '-', type: mode, is_zero_filtered })
    Metric_Login_Continue_Click({ account_type: mode, result: code == 0 ? 'success' : 'failed', error: code, sub_site: publicLocalData.value?.SiteUID || '' })
    evt.$emit('event-loading', false)
    const locationRiskId = info?.risk_id || ''
    if (code == 0) {
      if (relatedAcc.value.global && type == 'signup') {
        showInputTips(aliasTip.value, langText.value.SHEIN_KEY_PWA_24116)
        return
      }
      if (mode == 'email') {
        store.commit('login/setnewUIStates', { showType: 'email', type, locationRiskId, subscribe_status })
        setSignUpRetainModal(type)
        saSend('2-8-105', { scene: type, type: 'email', acc: relatedAcc.value })
        type == 'login' ? Metric_Login_Detail_Total({ account_type: 'email', sub_site: publicLocalData.value?.SiteUID || '' }) : Metric_Register_Detail_Total({ account_type: 'email', sub_site: publicLocalData.value?.SiteUID || '' })
        return
      }
      if (mode == 'phone') {
        evt.$emit('event-loading', true)
        store.commit('login/setnewUIStates', { type, locationRiskId })
        setSignUpRetainModal(type)
        await handleSendCode(
          'phone_login_register_verify',
          smsType,
          'continue_btn'
        )
        return
      }
    }
    if (mode == 'phone' && code == '100102') {
      showInputTips(aliasTip.value, langText.value.SHEIN_KEY_PWA_18690)
      return
    }
    showInputTips(aliasTip.value, tips || langText.value.SHEIN_KEY_PWA_14899)
  }

  const handlePhoneBlurCheck = async () => {
    const alias = newUIStates.value.alias
    const { code, abbr } = areaCode.value
    const aliasRes = handleFilterAreaCode(areaCode.value, alias)
    if (aliasRes == '') {
      showInputTips(aliasTip.value, langText.value.SHEIN_KEY_PWA_18690)
      return
    }
    const { code: validateCode } = await phoneCheckSer({
      alias_type: 2,
      alias: aliasRes,
      area_code: code,
      area_abbr: abbr,
    })
    if (validateCode != 0) {
      if (validateCode == -410) {
        showInputTips(aliasTip.value, langText.value?.SHEIN_KEY_PWA_18690)
      } else {
        showInputTips(aliasTip.value, langText.value?.SHEIN_KEY_PWA_20428)
      }
      return false
    }
    showInputTips(aliasTip.value, '')
    return true
  }

  return { handleUserLocation, handlePhoneBlurCheck }
}
