<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M3.60454 1.48714L2 3.2153H9L4.14955 1.36033C3.95902 1.28747 3.74333 1.33765 3.60454 1.48714Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 4.2876C1 4.01146 1.22386 3.7876 1.5 3.7876H10.5C10.7761 3.7876 11 4.01146 11 4.2876V6.5376C10.5858 6.5376 10.25 6.87338 10.25 7.2876C10.25 7.70181 10.5858 8.0376 11 8.0376V10.2876C11 10.5637 10.7761 10.7876 10.5 10.7876H1.5C1.22386 10.7876 1 10.5637 1 10.2876V8.0376C1.41421 8.0376 1.75 7.70181 1.75 7.2876C1.75 6.87338 1.41421 6.5376 1 6.5376V4.2876Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.62555 5.04474C7.38841 4.88447 7.05992 4.94236 6.89185 5.17403L4.27122 8.78634C4.10314 9.01802 4.15914 9.33575 4.39628 9.49602C4.63342 9.65628 4.96191 9.59839 5.12999 9.36672L7.75061 5.75441C7.91869 5.52274 7.8627 5.205 7.62555 5.04474ZM4.44397 6.63341C4.87362 6.63341 5.22192 6.29905 5.22192 5.88659C5.22192 5.47413 4.87362 5.13977 4.44397 5.13977C4.01432 5.13977 3.66602 5.47413 3.66602 5.88659C3.66602 6.29905 4.01432 6.63341 4.44397 6.63341ZM7.55586 9.62077C7.98551 9.62077 8.33381 9.28641 8.33381 8.87395C8.33381 8.4615 7.98551 8.12713 7.55586 8.12713C7.12621 8.12713 6.77791 8.4615 6.77791 8.87395C6.77791 9.28641 7.12621 9.62077 7.55586 9.62077Z"
      fill="#FA6338"
    />
  </svg>
</template>

<script>
export default {
  name: 'CouponsIcon'
}
</script>
