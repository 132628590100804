<template>
  <div class="filterAreaCodeTips-container">
    <s-alert
      type="info"
      jumpable
      @click.stop.native="handleShowModal"
    >
      {{ langText.SHEIN_KEY_PWA_28528 }}
    </s-alert>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'

const store = useStore()

const langText = computed(() => store.state.login.langText)

const handleShowModal = () => {
  store.commit('login/assignState', { isShowFilterAreaCodeModal: true })
// eslint-disable-next-line semi
};

</script>

<script>
export default {
  name: 'FilterAreaCodeTips'
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-max-specificity */
 .filterAreaCodeTips-container{
   /deep/.S-alert__simple.S-alert__cursor-pointer{
  height: 66px;
 }
 /deep/.S-alert__description{
  line-height: 0.382rem;
  color: @sui_color_gray_dark3;
 }
  /deep/ .ivULGa.S-alert__simple .S-alert__jumpbtn{
    transform: rotate(180deg);
  }
  /deep/ .ejXyOx.S-alert__simple .S-alert__jumpbtn{
    transform: rotate(180deg);
  }
 }
 
</style>
