<template>
  <div class="promotion-container">
    <PromotionBg class="promotion_bg" />
    <div class="promotion-content">
      <span class="promotion-content_text">
        {{ langText.SHEIN_KEY_PWA_27218 }}
      </span>
      <span class="promotion-content_price">
        {{ data.promotion_price }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import PromotionBg from './icon/PromotionBg.vue'
defineProps({
  data: {
    type: Object,
    default: () => { }
  }
})
const langText = computed(() => {
  return window.loginLanguage || window.LoginPageLangText || {}
// eslint-disable-next-line semi
});
</script>
<script>
export default {
  name: 'PromotionVue'
}
</script>

<style lang="less" scoped>
.promotion-container{
.promotion_bg{
  position: absolute;
  top: 0;
  left: 0;
}
.promotion-content{
  margin-top: 22px;
  width: 100%;
  text-align: center;
  color: @sui_color_discount;
  transform: translateY(5px);
  &_text{
    display: block;
    font-size: 16px;
    font-weight: 700;
  }
  &_price{
    display: block;
    transform: translateY(-5px);
    font-size: 32px;
    font-weight: 700;
  }
}
}
</style>
