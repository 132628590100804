<template>
  <s-drawer
    :visible="newUIStates.isToLogin"
    class="page__login-continue-toLogin"
    type="full"
    direction="rtl"
    :no-header="true"
    :append-to-body="true"
    :immediately-render="true"
  >
    <continuePage :codeResult="codeResult" type="continue" />
  </s-drawer>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import continuePage from '@login/views/NewMainUI/continuePage.vue'

const props = defineProps({
  codeResult: {
    type: Function,
    default: () => {},
  }
})

const store = useStore()

const newUIStates = computed(() => store.state.login.newUIStates)
const showIndex = computed(() => store.state.login.showIndex)

watch(showIndex, (v) => {
  if(!v){
    store.commit('login/setnewUIStates', { isToLogin: false, isSwitch: false})
  }
})



;
</script>

<script>
  export default {
    name: 'LoginContinue'
  }
</script>
