import { request } from './index'

export async function refuseSubScribe(params) {
  const { data }  = await request('post', `/api/auth/subScribe/unsubscribe/update`, params)
  return data
}

export async function agreeSubScribe(params) {
  const { data }  = await request('post', `/api/auth/subScribe/agreeSubscribe/update`, params)
  return data
}

export async function getEmailSubscribe(params) {
  const data = await request('post', `/api/auth/subScribe/getEmailSubscribe/get`, params)
  return data
}

