const { SiteUID } = gbCommonInfo

export const getSupportCountry = (list) => {
  const supportCountry = list.filter((v) => {
    if (SiteUID == 'm') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    if (SiteUID == 'rwm') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    return v.siteUid == SiteUID
  })
  return supportCountry
}
