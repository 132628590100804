
export const filterAreaCodeFn = (abt, filterList, areaCode, alias) => {
  if (!Array.isArray(filterList)) return alias
  if (abt == 'on' && filterList.length > 0) {
    const { abbr } = areaCode || {}
    if(filterList.includes(abbr)){
      const resAlias = alias?.replace(/^0+/, '')
      return resAlias
    }
  }
  return alias
}


export const filterZeroFormat = ({ abt, filterList, areaCode, alias, isFilter }) => {
  if (!Array.isArray(filterList)) return '-'
  const { abbr } = areaCode || {}
  if (!filterList.includes(abbr)) return '-'
  const isZero = alias[0] === '0'
  if (filterList.includes(abbr) && !isZero) return '-'
  if (isFilter) return '1'
  if(abt == 'off' && filterList.includes(abbr)) return '0'
}

