<template>
  <SDialog
    class="related-retain-modal"
    :visible="relatedRetainModal"
    :append-to-body="true"
    immediately-render
    :show-close="true"
    @close-from-icon="handleClose"
  >
    <template slot="title">
      {{ langText.SHEIN_KEY_PWA_24335 }}
    </template>
    <p
      class="content"
    >
      {{ langText.SHEIN_KEY_PWA_24336 }}
    </p>
    <SButtonGroup
      class="btns"
      width="100%"
    >
      <SButtonGroupItem
        :type="['primary', 'H72PX']"
        @click="handleBindAccount"
      >
        {{ langText.SHEIN_KEY_PWA_24337 }}
      </SButtonGroupItem>
      <SButtonGroupItem
        :type="['H72PX']"
        @click="handleContinue"
      >
        {{ langText.SHEIN_KEY_PWA_24338 }}
      </SButtonGroupItem>
    </SButtonGroup>
  </SDialog>
</template>

<script>
import {  mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'RelatedRetainModal',
  computed: {
    ...mapState('login', ['relatedRetainModal', 'langText']),
  },
  watch: {
    relatedRetainModal: {
      handler: function (val) {
        if (val) {
          daEventCenter.triggerNotice({
            daId: '2-8-112',
            extraData: {
              name: 'expose_multiple_accounts_register_popup'
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('login', ['assignState', 'setRelatedAcc']),
    handleBindAccount () {
      this.assignState({
        relatedRetainModal: false
      })
      this.setRelatedAcc({ is_relation: '', is_bind: 1  })
      daEventCenter.triggerNotice({
        daId: '2-8-112',
        extraData: {
          name: 'click_multiple_accounts_register_choose_account_popup'
        },
      })
    },
    handleContinue () {
      this.assignState({
        relatedRetainModal: false
      })
      this.setRelatedAcc({ is_relation: 1, is_bind: 1 })
      daEventCenter.triggerNotice({
        daId: '2-8-112',
        extraData: {
          name: 'click_multiple_accounts_register_continue_popup'
        },
      })
    },
    handleClose(){
      this.assignState({
        relatedRetainModal: false
      })
      this.setRelatedAcc({ is_relation: 1 })
      daEventCenter.triggerNotice({
        daId: '2-8-112',
        extraData: {
          name: 'click_multiple_accounts_register_cancel_popup'
        },
      })
    }
  },
}
</script>

<style lang="less" scoped>
.related-retain-modal {
  .content {
    margin-top: 0.32rem;
    text-align: center;
  }
  .btns {
    margin: 0.53rem 0;
  }
}
</style>
