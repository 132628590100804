<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6 12.7139L10.714 7.99981L6 3.28577V3.28577"
      stroke="#222222"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'MoreIcon'
}
</script>
