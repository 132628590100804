import { template } from '@shein/common-function'

export const smsSubScribePrivacyText = (opt = {}) => {
  const { from = '', language = {}, alias = '' } = opt
  const langText = window.LoginPageLangText || language || {}
  let str = ''
  const privacy = `<a href="javascript:void(0);" class="j-privacy-and-cookies" tabindex="0">${language.SHEIN_KEY_PWA_24590}</a>`
  const terms = `<a tabindex="0" href="javascript:void(0);" class="j-terms-and-conditions">${language.SHEIN_KEY_PWA_24589}</a>`
  if(from == 'phone-login'){
    str = langText?.SHEIN_KEY_PWA_30955
    return template(alias, terms, privacy, str || '')
  }
  if(from == 'phone-bind'){
    str = langText?.SHEIN_KEY_PWA_30911
  }
  return template(terms, privacy, str || '')
}
