

export function encryptPhoneNumber(v) {
  if (typeof v !== 'string') return v
  // 已脱敏
  if (v.includes('****')) return v

  const len = v.length

  if (len == 0) return v

  const markLen = Math.ceil(v.length / 2) // 脱敏长度
  const notLen = len - markLen

  const front = Math.ceil(notLen / 2)
  const end = notLen - front

  return v.slice(0, front) + '****' + v.slice(-end)
}
