<template>
  <s-drawer
    :visible="newUIStates?.showType == 'phone'"
    class="page__login-newUI-phonePannel"
    type="full"
    direction="rtl"
    :no-header="true"
    :append-to-body="true"
    :immediately-render="true"
    da-expose-code="page-login-NewphonePannel"
  >
    <div class="main-content-body">
      <login-head
        :showback="true"
        :show-logo="false"
        @back="goBack"
      />
      <template v-if="filterAreaCodeTip.show">
        <div class="filter-tips">
          <FilterAreaCodeTips />
        </div>
        <div class="tips-placeholder"></div>
      </template>
      <div
        v-show="codeMode == 'code'"
        class="page__login-newUI-code"
      >
        <h2 v-show="!isInRelatedAcc">
          <p>{{ subTitle }}</p>
        </h2>
        <related-acc-tip />
        <p class="sub-tips">
          <span v-html="popMsgTips"></span>
          <em 
            da-event-click="2-8-106" 
            :data-scene="newUIStates.type"
            data-type="phone"
            @click="goBack('edit')"
          >{{ langText.SHEIN_KEY_PWA_23929 }}</em>
        </p>
        <!-- 验证码 -->
        <div
          class="page__login_code-number"
          :class="codePopCodeTip.show ? 'error' : ''"
        >
          <p>
            <span :class="{ active: inputCode.length == 0 && inputCodeFocus }">{{ inputCode.slice(0,1) }}</span>
            <span :class="{ active: inputCode.length == 1 && inputCodeFocus }">{{ inputCode.slice(1,2) }}</span>
            <span :class="{ active: inputCode.length == 2 && inputCodeFocus }">{{ inputCode.slice(2,3) }}</span>
            <span :class="{ active: inputCode.length == 3 && inputCodeFocus }">{{ inputCode.slice(3,4) }}</span>
            <span :class="{ active: inputCode.length == 4 && inputCodeFocus }">{{ inputCode.slice(4,5) }}</span>
            <span :class="{ active: inputCode.length == 5 && inputCodeFocus }">{{ inputCode.slice(5,6) }}</span>
            <input
              v-model.trim="inputCode"
              type="text"
              unselectable="on"
              maxlength="6"
              @click="handleInputCodeClick"
              @input="handleInputCodeInput"
              @blur="handlePhoneCheckInputParams"
            />
          </p>
          <p
            class="error-tip"
            tabindex="0"
          >
            {{ codePopCodeTip.txt }}
          </p>
        </div>
        <!-- 倒计时 -->
        <div class="countdowntime">
          <em
            v-show="codeDownTime > 0"
            class="downtime"
          >{{ codeDownTime }}S</em>
          <em
            v-show="codeDownTime <= 0"
            :data-type="sendType == 'sms' ? '1' : '2'"
            @click="handleSendCode()"
          >{{ langText.SHEIN_KEY_PWA_14897 }}</em>
        </div>
      </div>
      <!-- 手机号码登陆密码 -->
      <div
        v-show="codeMode == 'password'"
        class="page__login-newUI-password"
      >
        <h2>+{{ areaCode.code }} {{ phonePwdTips }}</h2>
        <div class="input-field">
          <login-input
            v-model="phonePassword"
            type="password"
            :tips="codePopPasswordTip.txt"
            :label="langText.SHEIN_KEY_PWA_15072"
            :mode="'email'"
            ui-type="new"
            @input="handlePopPasswordChange"
            @blur="handlePhoneCheckInputParams"
          />
        </div>
      </div>
      <div
        v-show="newUIStates.type == 'login'"
        class="page__login-newUI-switchMode"
      >
        <p
          v-show="codeMode == 'code'"
          class="page__login-switch-codeMode"
        >
          <span
            da-event-click="2-8-41"
            data-type="1"
            @click="handleChangeLoginMode(2)"
          >
            <i class="suiiconfont sui_icon_change_24px"></i>
            {{ langText.SHEIN_KEY_PWA_14776 }}
          </span>
        </p>
        <p
          v-show="codeMode == 'password'"
          class="page__login-switch-codeMode page__login-switch-password"
          data-type="2"
        >
          <span
            da-event-click="2-8-43"
            @click="handleChangeLoginMode(1)"
          >
            <i class="suiiconfont sui_icon_change_24px"></i>
            {{ langText.SHEIN_KEY_PWA_18618 }}
          </span>
          <a
            href="javascript:;"
            da-event-click="2-8-55"
            data-type="phone" 
            :data-mode="1"
            @click="handleForgetPsd"
          >{{ langText.SHEIN_KEY_PWA_15582 }}</a>
        </p>
      </div>
      <!-- 隐私政策 && 订阅 -->
      <PrivacyBottom
        type="phone"
        @privacy-change="handlePrivacy"
        @subscrip-change="handleSubscrib"
      />
      <!-- 登陆或者注册按钮 -->
      <div class="actions">
        <LoginPointButton
          v-if="newUIStates.type == 'login'"
          :text="langText?.SHEIN_KEY_PWA_15578"
          :langText="langText"
          :configData="LoginButtonPoint"
          :type="['primary', 'H88PX']"
          @click="handlePhoneLogin"
        />
        <LoginPointButton
          v-if="newUIStates.type == 'signup'"
          :text="langText?.SHEIN_KEY_PWA_15579"
          :langText="langText"
          :configData="LoginButtonPoint"
          :type="['primary', 'H88PX']"
          @click="handlePhoneSignup"
        />
      </div>
      <!-- whatsApp 发送验证码切换方式 -->
      <div
        v-show="commonAbt.WhatsAppSetup.show == 'on' && codeMode == 'code'"
        class="page__login-switch-sendtype"
      >
        <a
          v-show="sendType == 'whatsApp' && codeDownTime == 0"
          href="javascript:void(0)"
          da-event-click="2-8-89"
          data-type="1"
          @click="handleSendTypeChange(false)"
        ><i
          class="suiiconfont sui_icon_phone_20px"
          style="font-size: 18px"
        ></i>{{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'SMS') }}</a>
        <a
          v-show="sendType == 'sms' && codeDownTime == 0"
          href="javascript:void(0)"
          da-event-click="2-8-89"
          data-type="2"
          @click="handleSendTypeChange(false)"
        ><i
          class="suiiconfont sui_icon_whatsapp_16px"
          style="font-size: 18px"
        ></i>{{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'whatsApp') }}</a>
      </div>
      <!-- 新人权益 -->
      <NewUserRights
        v-show="newUIStates.type == 'signup'"
        scene="register"
        type="phone"
      />
    </div>
    <div class="main-content-footer">
      <!-- 弱合规隐私政策 -->
      <WeekPrivacy
        v-show="newPrivacyAbt.type == 'no'"
        :type="newUIStates.type"
        location="phone_login"
      />
    </div>
    <!-- 风控验证 -->
    <TemporaryVue
      ref="temporaryRef"
      :langText="langText"
    />
    <LazyMount mount-prop-name="isShow">
      <PhoneSendTypeModal
        :is-show="sendTypeChangePop"
        :send-type="sendType"
        @SendTypeChange="handleSendTypeChange"
        @SendTypeResend="handleSendTypeResend"
      />
    </LazyMount>
  </s-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import LoginHead from './head.vue'
import WeekPrivacy from './week_privacy.vue'
import PrivacyBottom from '../../../components/PrivacyBottom/index.vue'
import TemporaryVue from '@login/components/MultiAccount/Temporary.vue'
import NewUserRights from '../../../components/NewUserRights/index.vue'
import RelatedAccTip from '../../../components/RelatedAccount/RelatedAccountTip.vue'
import PhoneSendTypeModal from '@login/components/PhoneSendTypeModal/index.vue'
import { FilterAreaCodeTips } from '@login/components/index'
import { BaseMixin, MergePhoneMixin } from '../../../mixin/index'
import LoginPointButton from '@login/components/LoginPointButton/index.vue'
import { formatErrorPhoneSa, sleep } from '../../../util'
import analysisIndex from '../../../analysis/index'
import { getSessionStorage, removeSessionStorage } from '@shein/common-function'
import { Metric_Login_Detail_Click_Total, Metric_Register_Detail_Click_Total } from '@login/utils/monitorLog.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const { sensorsSend } = analysisIndex
const { IS_RW, SiteUID } = gbCommonInfo

export default {
  name: 'NewPhonePannel',
  components: {
    LoginHead,
    PrivacyBottom,
    NewUserRights,
    WeekPrivacy,
    RelatedAccTip,
    LoginPointButton,
    TemporaryVue,
    FilterAreaCodeTips,
    PhoneSendTypeModal
  },
  mixins: [ BaseMixin, MergePhoneMixin ],
  data: () => ({
    IS_RW,
    aliasTip: { show: false, txt: '' },
    checkboxPrivacy: false,
    checkPhoneSubsrib: false,
    codeMode: 'code',
  }),
  computed: {
    ...mapState('login', ['langText', 'newUIStates', 'areaCode', 'WEB_CLIENT', 'newPrivacyAbt', 'defaultLocation', 'commonAbt', 'isShowRetainModal', 'RetainModalConfig', 'showPointRetainModal', 'closeRetainModal', 'filterAreaCodeTip', 'LoginButtonPoint']),
    subTitle () {
      const type = this.newUIStates.type
      if (type == 'login') {
        return this.langText.SHEIN_KEY_PWA_23928
      }
      if (type == 'signup') {
        return this.langText.SHEIN_KEY_PWA_23930?.replace('{0}', this.WEB_CLIENT?.toUpperCase())
      }
      return ''
    },
    // 是否是在展示关联账号, 关联账号模式需要隐藏非必要元素
    isInRelatedAcc () {
      return this.relatedAcc.global
    },
    isShowPhonePannel() {
      return this.newUIStates?.showType == 'phone'
    },
    popMsgTips () {
      let alias = this.newUIStates.alias
      alias = this.handleFilterAreaCode(this.areaCode, alias)
      if (this.commonAbt.WhatsAppSetup.show == 'on') {
        return this.langText.SHEIN_KEY_PWA_22294.replace('{0}', `<i>${this.sendType}</i>`).replace('%x', `+${this.areaCode.code} ${alias}`)
      }
      return this.langText.SHEIN_KEY_PWA_20733.replace('{0}', `+${this.areaCode.code} ${alias}`)
    },
    phonePwdTips(){
      let alias  = this.newUIStates.alias
      alias = this.handleFilterAreaCode(this.areaCode, alias)
      return alias
    }
  },
  watch: {
    'newUIStates.sendType': {
       handler (v) {
        this.sendType = v
      }, immediate: true
    },
    'relatedAcc.is_bind': {
      handler (v) {
       if (v == 1) {
        this.handlePhoneSignup()
        }
      }, immediate: true
    },
    'newUIStates.showType': {
      handler(val){
       if(val == 'phone'){
        removeSessionStorage('checkboxPrivacy')
       }
      }, immediate: true
    },
    closeRetainModal : {
      handler(val){
        if(val == 'phone') {
          this.handlePhoneGoBack()
        }
      },immediate: true
    } 
  },
  methods: {
    ...mapMutations('login', ['setnewUIStates', 'setForgetPassword', 'assignState', 'setRegisterPrivacyModal']),
    handleCloseRetainModal (from) {
      if(from == 'edit') return
      if(isLogin()) return
      const LoginRegisterRetian = this.commonAbt?.LoginRegisterRetian
      const LoginRegisterRetianPops = this.commonAbt?.LoginRegisterRetianPops
      if (LoginRegisterRetian == 'on' && this.isShowRetainModal != true && !IS_RW) {
        const result = LoginRegisterRetianPops.split(',')
        const cacheRetainModal = getSessionStorage('cacheRetainModal') || []
        if (cacheRetainModal.includes('newUser') || this.RetainModalConfig.type != 'newUser') return
        const type = this.RetainModalConfig.type
        if (result.includes(type) && !cacheRetainModal.includes(type)) {
          this.assignState({
            showPointRetainModal: true
          })
          if (!cacheRetainModal.includes(this.RetainModalConfig.type)) {
            this.assignState({
              isShowRetainModal: true
            })
          }
        }
      }
    },
    goBack (from) {
      if(!this.newUIStates.isToLogin){
        this.handleCloseRetainModal(from)
      }
      sleep(0)
      if (this.showPointRetainModal == true) {
        return
      }
      this.handlePhoneGoBack()
    },
    handlePhoneGoBack(){
      this.codeMode = 'code'
      this.phonePassword = ''
      this.inputCode = ''
      this.codeDownTime = 0
      // 清除定时器
      if (this.downtimer) clearTimeout(this.downtimer)
      this.showInputTips(this.__codePopTip, '')
      this.showInputTips(this.inputCodeTip, '')
      this.setnewUIStates({ showType: 'continue' })
      this.assignState({
        filterAreaCodeTip: {
          show: false,
          area_code: {}
        },
      })
    },
    handlePrivacy (v) {
      this.checkboxPrivacy = v
      this.assignState({
        checkboxPrivacyValue: this.checkboxPrivacy
      })
    },
    handleSubscrib (v) {
      this.checkPhoneSubsrib = v
    },
    handleForgetPsd () {
      const { alias } = this.newUIStates
      this.setForgetPassword({ show: true, phone: alias, type: 'phone' })
    },
    /**
     * 手机号码验证码发送
     * @param {*} sendtype 验证发送类型, 默认为sms，: sms or whatsapp
     */
    async handleSendCode (scene = 'phone_login_register_verify', smsType, form = '') {
      if (this.codeDownTime > 0) return
      let alias = this.newUIStates.alias
      alias = this.handleFilterAreaCode(this.areaCode, alias)
      const { code, abbr } = this.areaCode
      const sendtype = smsType ? smsType : this.sendType
      const params = {
        alias,
        alias_type: 2,
        scene,
        area_code: code,
        area_abbr: abbr,
        send_type: sendtype == 'whatsApp' ? 1 : 0,
        third_party_type: 8,
      }
      const result = await this.requestSendCode(params, sendtype, form)
      return result
    },
    handlePhoneLogin () {
      const { alias, locationRiskId } = this.newUIStates
      const aliasRes = this.handleFilterAreaCode(this.areaCode, alias)
      const { inputCode: verification_code, codeMode, phonePassword: password } = this
      const { code: area_code, abbr: area_abbr } = this.areaCode
      Metric_Login_Detail_Click_Total({ account_type: 'phone', sub_site: SiteUID || '' })
      // 参数检查
      if (!this.handlePhoneCheckInputParams()) {
        const samsg = formatErrorPhoneSa(verification_code, password, codeMode)
        sensorsSend('2-8-108', { result_reason: samsg, acc: this.relatedAcc, authentication: this.codeMode })
        return
      }
            
      const params = {
        alias_type: 2,
        alias: aliasRes,
        verification_code,
        area_code: area_code,
        area_abbr, 
        login_type: codeMode == 'code' ? 1 : 2,
        password,
        clause_flag: this.newPrivacyAbt.type == 'yes' ? 1 : 0,
        daId: '2-8-108',
        biz_uuid: locationRiskId,
        send_type: this.sendType == 'whatsApp' ? 1 : 0,
      }
      this.requestPhoneLogin(params)
    },
    handlePhoneSignup () {
      if(this.relatedAcc.isRetain && this.relatedAcc.is_relation != 1 && this.relatedAcc.is_bind != 1) {
        this.assignState({
          relatedRetainModal: true
        })
        return
      }
      const { alias, locationRiskId } = this.newUIStates
      const aliasRes = this.handleFilterAreaCode(this.areaCode, alias)
      const { inputCode: verification_code } = this
      const { code: area_code, abbr: area_abbr } = this.areaCode
      Metric_Register_Detail_Click_Total({ account_type: 'phone', sub_site: SiteUID || '' })
      if (!this.handlePhoneCheckInputParams()) {
        return
      }
      const params = {
        alias_type: 2,
        alias: aliasRes,
        verification_code,
        area_code: area_code.replace('+', ''),
        area_abbr,
        force_bind: 0,
        daId: '2-8-107',
        biz_uuid: locationRiskId,
        send_type: this.sendType == 'whatsApp' ? 1 : 0,
      }
      if (this.checkPhoneSubsrib) params.checkboxSubscrib = 1
      const policyAuthorizePopup = this.commonAbt.PolicyAuthorizePopup || ''
      if (this.newPrivacyAbt.type == 'yes' && !this.checkboxPrivacy) {
        if (policyAuthorizePopup == 'new') {
          this.setRegisterPrivacyModal({
            cb: () => {
              this.assignState({
                checkboxPrivacyValue: true
              })
              this.handlePrivacy(true)
              params.clause_flag = 1
              params.clause_agree = 1
              this.requestPhoneSignup(params)
            }
          })
        } else {
          if (SiteUID == 'mkr') {
            this.$toast(this.langText.SHEIN_KEY_PWA_31878)
          } else {
            this.$toast(this.langText.SHEIN_KEY_PWA_17949)
          }
          sensorsSend('2-8-107', {
            mode: 'phone',
            result_reason: 'privacy_policy_error',
            acc: this.relatedAcc,
            ...this.getSubscribeSaParams('2-8-107')
          })
        }
        return
      }
      const isAgree = getSessionStorage('checkboxPrivacy') || ''
      if(isAgree == true) {
        params.clause_flag = 1
        params.clause_agree = 1
      }
      // 调用 requestPhoneSignup 方法
      this.requestPhoneSignup(params)
    }
  }
}
</script>

<style lang="less">
.page__login-newUI-phonePannel {
  .S-drawer__body {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    .main-content-body {
      flex: 1 1 100%;
    }
    .main-content-fotoer {
      flex: 0 0 auto;
    }
  }
  .filter-tips{
    position: absolute;
    left: 0;
    right: 0;
  }
  .tips-placeholder{
    height: 49px;
  }
  h2 {
    padding: 32px 0 8px 0;
    .font-dpr(36px);
    line-height: 1;
  }
  .sub-tips {
    color: @sui_color_gray_dark2;
    .font-dpr(28px);
    line-height: 1.2;
    i {
      font-weight: bold;
    }
    em {
      color: @sui_color_link;
    }
  }
  .page__login_code-number {
    margin: 24px 0 16px 0;
  }
  .countdowntime {
    display: flex;
    justify-content: center;
    padding-bottom: 13px;
    em {
      color: @sui_color_link;
      font-weight: bold;
    }
    .downtime {
      color: #222;
      padding: 0 4px;
    }
    em.disabled {
      color: @sui_color_gray_dark2;
    }
  }
  .forgot-pass {
    padding: 12px 0;
    a {
      text-decoration: none;
    }
  }
  .actions {
    padding-top: 16px;
    button {
      width: 100%;
    }
  }
  .page__login-switch-codeMode {
    margin-bottom: 0;
    margin-top: 13px;
  }
  .page__login-switch-password {
    padding-bottom: 16px;
  }
  .page__login_related-accTips {
    padding: 0;
  }
  .page__login-newUI-password {
    h2 {
      padding-bottom: 8px;
    }
  }
  .page__login_code-number .error-tip {
    margin-top: 9px;
  }
  .page__login-weekPrivacy {
    position: relative;
    bottom: 0;
    padding: 0;
    padding-bottom: 10px;
  }
}
</style>
