<template>
  <!-- 手机号码发送类型切换 -->
  <s-dialog
    class="page-login__popSendType"
    :visible="isShow"
    :append-to-body="true"
    :show-close="true"
    immediately-render
    da-expose-code="page-login-mergePhonePop-login"
    @close-from-icon="handleSendTypeClose"
  >
    <p class="title">
      {{ langText.SHEIN_KEY_PWA_22296 }}
    </p>
    <s-button-group
      :num="2"
      style="margin: 28px 0 20px 0"
      width="100%"
    >
      <s-button-group-item
        v-show="sendType == 'whatsApp'"
        da-event-click="2-8-91"
        data-type="1"
        :type="['primary', 'H72PX']"
        @click="handleSendTypeChange"
      >
        {{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'SMS') }}
      </s-button-group-item>
      <s-button-group-item
        v-show="sendType == 'sms'"
        da-event-click="2-8-91"
        data-type="2"
        :type="['primary', 'H72PX']"
        @click="handleSendTypeChange"
      >
        {{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'whatsApp') }}
      </s-button-group-item>
      <s-button-group-item
        da-event-click="2-7-39"
        :type="['H72PX']"
        @click="handleSendTypeResend"
      >
        {{ langText.SHEIN_KEY_PWA_14897 }}
      </s-button-group-item>
    </s-button-group>
  </s-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'PhoneSendType',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    sendType: {
      type: String,
      default: 'sms'
    }
  },
  computed: {
    ...mapState('login', ['langText'])
  },
  methods: {
    ...mapMutations('login', ['assignState']),
    handleSendTypeClose() {
      this.assignState({
        sendTypeChangePop: false
      })
    },
    handleSendTypeChange() {
      this.$emit('SendTypeChange')
    },
    handleSendTypeResend(){
      this.$emit('SendTypeResend')
    }
  }
}
</script>

