<template>
  <s-drawer
    :visible="show"
    :size="'80rem'"
    :append-to-body="true"
    @close-from-icon="close"
  >
    <template slot="top">
      {{ title }}
    </template>
    <ul class="page-login__pop-select-area">
      <li
        v-for="item in countryCodes"
        :key="item.abbr"
        :class="item.abbr == abbr ? 'active' : '' "
        @click="handleItemClick(item)"
      >
        {{ item.name }} +{{ item.code }}
        <i
          v-if="item.abbr == abbr"
          class="suiiconfont sui_icon_selected_16px"
        ></i>
      </li>
    </ul>
  </s-drawer>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { phoneCodeCache } from '@login/util'

daEventCenter.addSubscriber({ modulecode: '2-8' })

export default {
  name: 'PhoneAreaPop',
  props: {
    show: { type: Boolean, default: false },
    abbr: { type: String, default: '' },
    countryCodes: { type: Array, default: () => ([]) },
    title: { type: String, default: '' },
  },
  watch: {
    show(v) {
      if (v) {
        daEventCenter.triggerNotice({ daId: '2-8-72',  extraData: {
          is_common_areacode: 0,
        } })
      }
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },
    handleItemClick (item) {
      phoneCodeCache.set(item.abbr, 0)
      this.$emit('select-item', item)
      daEventCenter.triggerNotice({ daId: '2-8-71', extraData: { result: item.abbr == this.abbr ? 'not_change' : 'change' } })
    }
  }
}
</script>

<style lang="less">
.page-login {
  &__pop-select-area {
    li {
        padding: 12px;
        .font-dpr(28px);
        color: #222;
        display: flex;
        align-items: center;
        justify-content: space-between;
        [class*="suiiconfont"] {
            font-size: 20px;
            color: @sui_color_main;
        }
    }
    li.active {
        font-weight: bold;
        color: @sui_color_main;
      & when (@IS_RW) {
          font-family: Adieu;
      }
    }
  }
}

</style>
