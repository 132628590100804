<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4375 9.0625V5H9.5625V9.0625H5.5V10.9375H9.5625V15H11.4375V10.9375H15.5V9.0625H11.4375Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddIcon'
}
</script>
