<template>
  <div class="tip-container">
    <div class="tip-header_block">
      <span>{{ langText.SHEIN_KEY_PWA_22719 }}</span>
      <Icon 
        name="sui_icon_close_16px_2" 
        size="16px" 
        color="#959595"
        @click="closeTip('close')"
      />
    </div>
    <div class="tip-content_block">
      <div class="content-item">
        {{ contentText1 }}
      </div>
      <div class="content-item">
        {{ contentText2 }}
      </div>
      <div class="content-item">
        {{ contentText3 }}
      </div>
    </div>
    <div class="tip-footer_block">
      <div class="footer-ok-button">
        <s-button
          :type="['primary', 'H40PX']"
          :width="'100%'" 
          @click="closeTip('ok')"
        >{{ langText.SHEIN_KEY_PWA_24561 }}</s-button>
      </div>
      <div 
        v-if="commonAbt.forgetPwdQA"
        class="footer-tip_text"
      >
        <a href="javascript:void(0);" @click="handleVerify">{{ langText.SHEIN_KEY_PWA_22727 }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { mapState } from 'vuex'
import { template } from '@shein/common-function'
import { clickCannotAcceptCodePop } from 'public/src/pages/login/utils/loginAnalysis.js'
import { encryptPhoneNumber } from '@login/utils/authUilt.js'
const { host, langPath } = gbCommonInfo

export default {
  name: 'UnacceptTableTip',
  components: {
    Icon
  },
  props: {
    type: {
      type: String,
      default: 'phone'
    },
    alias: {
      type: String,
      default: ''
    },
    riskVerify: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('login', ['langText', 'areaCode', 'commonAbt']),
    contentText1(){
      return template(encryptPhoneNumber(this.alias), this.langText.SHEIN_KEY_PWA_22802 )
    },
    contentText2(){
      return template(encryptPhoneNumber(this.alias), this.langText.SHEIN_KEY_PWA_22803 )
    },
    contentText3(){
      return template(encryptPhoneNumber(this.alias), this.langText.SHEIN_KEY_PWA_22804 )
    }
  },
  methods: {
    closeTip(btn_type){
      clickCannotAcceptCodePop({
        btn_type
      })
      this.$emit('close')
    },
    async handleVerify(){
      clickCannotAcceptCodePop({
        btn_type: 'other_solutioins'
      })
      const { alias, type, areaCode } = this
      const params = {
        validate_type: 'qa_online',
        validate_scene: 'forgotPassword',
        validate_direction: `${host + langPath}/user/reset_password`,
        validate_param: {
          alias,
          alias_type: type === 'email' ? 1 : 2, // 1: 邮箱； 2:手机
          area_code: type === 'email' ? '' : areaCode?.code, // 区号
        }
      }
      try {
        const { isSuccess, code, info } = await this.riskVerify?.doVerify(params)
        if(isSuccess && info){
          window.location.href = info
          return
        }else if(code === '9001'){
          this.$toast(info, 1000)
          return
        }
        this.$toast(this.langText?.SHEIN_KEY_PWA_14899, 1000)
      }catch (err){
        this.$toast(this.langText?.SHEIN_KEY_PWA_14899, 1000)
      }
    }
  },
  emits: ['close']
}
</script>

<style lang="less" scoped>
.tip-container{
  position: relative;
  font-family: "SF Pro";
  font-size: 14px;
  font-weight: 400;
  .sui-icon-common__wrap{
    position: absolute;
    right: 2px;
    top: 2px;
    color: @sui_color_gray_light1;
  }
}
.tip-header_block{
  position: relative;
  display:flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  .border-dpr(border-bottom,1px,@sui_color_gray_weak1);
  
  color: var(---sui_color_gray_dark3, #767676);
  
}

.tip-content_block{
    padding: 12px 0;
    color: var(---sui_color_gray_dark1, #222);
    .content-item{
      padding: 0 12px 10px;
    }
}

.tip-footer_block{
  .footer-ok-button{
    padding: 8px 12px;
  }
  .footer-tip_text{
    display: flex;
    padding: 6px 10px 20px 10px;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    a{
      text-decoration: none;
      color: var(---sui_color_link, #2D68A8);
    }
  }
}
</style>
