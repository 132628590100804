<template>
  <s-dialog
    :visible="showfilterAreaCodeModal"
    :show-close="true"
    :append-to-body="true"
    :immediately-render="true"
    :close-on-click-modal="false"
    @close="handleCloseModal"
  >
    <template slot="title">
      {{ langText.SHEIN_KEY_PWA_28529 }}
    </template>
    <p class="filter-modal_content">
      {{ descText }}
    </p>
    <template
      slot="footer"
    >
      <s-button
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="handleCloseModal"
      >
        {{ langText.SHEIN_KEY_PWA_21364 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import { template } from '@shein/common-function'
import { exposeFilterZeroPopup } from '@login/utils/loginAnalysis'

const store = useStore()

const langText = computed(() => store.state.login.langText)
const showfilterAreaCodeModal = computed(() => store.state.login.isShowFilterAreaCodeModal)
const filterAreaCodeTipValue = computed(() => store.state.login.filterAreaCodeTip)

const descText = computed(() => {
  const desc = langText.value?.SHEIN_KEY_PWA_28530
  const { area_code, aliasRes } = filterAreaCodeTipValue.value
  const { code } = area_code || {}
  const text = `+${code} ${aliasRes}`
  return template(text, desc)
})

watch(showfilterAreaCodeModal, (val) => {
  if(val){
    exposeFilterZeroPopup()
  }
}, { immediate: true })

const handleCloseModal = () => {
  store.commit('login/assignState', { isShowFilterAreaCodeModal: false })
// eslint-disable-next-line semi
};

</script>

<script>
export default {
  name: 'FilterAreaCodeModal'
}
</script>

<style lang="less" scoped>
/* stylelint-disable declaration-no-important */
/deep/.S-dialog__normal{
    width: 8.72rem !important;
    max-width: 8.72rem !important;
}
  .filter-modal_content{
    margin-top: 12px;
    font-size: 14px;
    line-height: 16.71px;
    color: @sui_color_gray_dark1;
  }
</style>
