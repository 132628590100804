<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      fill="#E5E5E5"
    />
    <mask
      id="mask0_305_34604"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="30"
      height="30"
    >
      <path
        d="M15 0.5C23.0081 0.5 29.5 6.99187 29.5 15C29.5 23.0081 23.0081 29.5 15 29.5C6.99187 29.5 0.5 23.0081 0.5 15C0.5 6.99187 6.99187 0.5 15 0.5Z"
        fill="#F6F6F6"
        stroke="#E5E5E5"
      />
    </mask>
    <g mask="url(#mask0_305_34604)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2177 17.8189C18.5919 17.8189 21.3271 15.0836 21.3271 11.7095C21.3271 8.33537 18.5919 5.6001 15.2177 5.6001C11.8436 5.6001 9.10835 8.33537 9.10835 11.7095C9.10835 15.0836 11.8436 17.8189 15.2177 17.8189ZM26.4357 30.7821C26.4357 24.5867 21.4133 19.5643 15.2179 19.5643C9.02241 19.5643 4 24.5867 4 30.7821C4 36.9776 9.02241 42 15.2179 42C21.4133 42 26.4357 36.9776 26.4357 30.7821Z"
        fill="#BBBBBB"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AccountIcon'
}
</script>
