<template>
  <div v-show="canSwitchSite">
    <div
      v-show="commonAbt.switchSite.login == 'on'"
      class="page__login_swithSiteTabNew"
    >
      <p
        da-event-click="2-8-18"
        @click="handleSwitchSite" 
      >
        <sui_icon_location_15px_2 size="12px" />
        <span class="page__login-default-location">{{ defaultLocation.country }}</span>
        <sui_icon_more_down_12px_2 size="12px" />
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { checkLocationClauseSer } from '../../service'
import { sui_icon_location_15px_2, sui_icon_more_down_12px_2 } from '@shein-aidc/icon-vue2'
import { getQueryString } from '@shein/common-function'
import analysisIndex from '../../analysis/index'
const { loginPageFrom } = analysisIndex

export default {
  name: 'LoginSwitchSiteTab',
  components: { sui_icon_location_15px_2, sui_icon_more_down_12px_2 },
  data: () => {
    return {
      querySwitch: '',
    }
  },
  computed: {
    ...mapState('login', ['langText', 'defaultLocation', 'commonAbt', 'newPrivacyAbt', 'relatedAcc', 'preloadData']),
    isShow () {
      // 来自
      if (this.relatedAcc.global) return false
      return true
    },
    // 默认支持
    canSwitchSite() {
      return this.querySwitch !== '0' && this.isShow
    }
  },
  created () {
    this.querySwitch = typeof window === 'undefined' ? this.preloadData.canSwitchSite : getQueryString({ key: 'canSwitchSite' })
  },
  methods: {
    ...mapMutations('login', ['setDefaultLocation', 'setNewPrivacyAbt']),
    handleSwitchSite () {
      window.SHEIN_SWITCH_SITE.show({
        needPopLogin: true,
        countryValue: this.defaultLocation.country,
        from: 'login',
        from_scence: loginPageFrom(),
        selected: this.defaultLocation.value,
        cb: this.setLocation,
      })
    },
    setLocation (v) {
      this.setDefaultLocation({ ...v })
      this.checkLocationClause()
    },
    async checkLocationClause () {
      const { origin } = this.newPrivacyAbt
      if (origin != 'mix') return
      const { id, ipIsInSite, originPrivacyType } = this.defaultLocation
      const data = await checkLocationClauseSer({ locationId: id })
      const locationType = data?.info?.clause_country_type || 0
            
      // 弱合规切强合规
      if (locationType == 1) {
        this.setNewPrivacyAbt({ param: 'yes', loading: false })
        this.setDefaultLocation({ forceId: this.defaultLocation.id })
        return
      }

      // ip所属地区不在站点支持范围内，如果是弱合规就切成弱合规
      if (!ipIsInSite && locationType == 0) {
        this.setDefaultLocation({ forceId: '' })
        this.setNewPrivacyAbt({ param: 'no', loading: false })
        return
      }
      // ip所属地区在站点支持范围内，并且ip归属地合规类型为弱合规，目标区为弱合规
      if (ipIsInSite && originPrivacyType == 0 && locationType == 0) {
        this.setDefaultLocation({ forceId: '' })
        this.setNewPrivacyAbt({ param: 'no', loading: false })
        return
      }
    }
        
  },
}
</script>

<style lang="less" scoped>
.page__login_swithSiteTabNew {
  text-align: center;
  p {
    background: @sui_color_gray_weak2;
    padding: 7px 12px;
    margin: 0;
    border-radius: 35px;
    // margin-top: 12px;
    display: inline-flex;
    color: @sui_color_gray_dark1;
    justify-content: center;
    align-items: center;
  }
  i {
    font-size: 16px;
  }
  .page__login-default-location{
    font-size: 12px;
    line-height: 1;
    padding: 0 5px;
    min-width: 80px;
  }
}
</style>
