<template>
  <div class="login__phone_countdowntime">
    <em
      v-if="codeDownTime > 0"
      class="code__downtime"
    >{{ codeDownTime }}S</em>
    <em
      v-if="codeDownTime <= 0"
      :data-type="sendType == 'sms' ? '1' : '2'"
      @click="handleClick"
    >{{ reSend }}</em>
  </div>
</template>

<script setup>
defineProps({
  reSend: {
    type: String,
    default: ''
  },
  codeDownTime: {
    type: Number,
    default: 0
  },
  sendType: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'LoginCountDownTime'
}
</script>

<style lang="less" scoped>
.login__phone_countdowntime{
  display: flex;
  justify-content: center;
  padding-bottom: 13px;
  em {
      color: @sui_color_link;
      font-weight: bold;
  }
  .code__downtime {
      color: #222;
      padding: 0 4px;
  }
  em.disabled {
      color: @sui_color_gray_dark2;
  }
}
</style>
