import { ref, computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import { filterAreaCodeFn } from '@login/utils/phoneUtil'
import { showRegisterSuccessPopFormat } from '@login/utils/common.js'
const { FILTER_AREACODE_LIST } = gbCommonInfo

export default function useCommonHandle () {
  
  const store = useStore()

  const aliasTip = ref({ show: false, txt: '' })
  const aliasInputFocus = ref(false)

  const commonAbt = computed(() => store.state.login.commonAbt)
  const loginPopConfig = computed(() => store.state.login.loginPopConfig)

  const aliasInputStyleClass = computed(() => {
    return {
      'input_filed-text': true,
      input_filedFocus: aliasInputFocus,
    }
  })

  const showInputTips = (tip, msg, show = true) => {
    tip.show = show
    tip.txt = msg
  }

  const handleFilterAreaCode = (area_code, alias) => {
    const PhoneZeroFiltered = commonAbt.value?.PhoneZeroFiltered
    const aliasRes = filterAreaCodeFn(
      PhoneZeroFiltered,
      FILTER_AREACODE_LIST,
      area_code,
      alias
    )
    if (aliasRes != alias) {
      store.commit('login/assignState', {  filterAreaCodeTip: {
        show: true,
        area_code,
        aliasRes,
      } })
    }
    return aliasRes
  }

  const setCommonShowPop = () => {
    const { from, bi } = loginPopConfig.value || {}
    const loginFrom = bi || from
    const isSwitch = showRegisterSuccessPopFormat(loginFrom)
    return isSwitch
  }

  return { aliasTip, aliasInputFocus, aliasInputStyleClass, showInputTips, handleFilterAreaCode, setCommonShowPop }
}
