import { template } from '@shein/common-function'

export const formatSubscript = ({ isContinue, langText, configData }) => {
  const subscriptData = {
    text: '',
    type: '', 
    isSingleCoupon: false,
    borderTopColor: '',
    background: ''
  }
  if(configData.type == 'freeShipping'){
    Object.assign(subscriptData, {
      text: isContinue ? langText?.SHEIN_KEY_PWA_30106 : langText?.SHEIN_KEY_PWA_30107,
      type: 'freeShipping',
      borderTopColor: '#16533A',
      background: '#198055'
    })
  }
  if(configData.type == 'promotion'){
    const price = configData.promotion_price
    Object.assign(subscriptData, {
      text: isContinue ? template(price, langText?.SHEIN_KEY_PWA_30110) : template(price, langText?.SHEIN_KEY_PWA_30111),
      type: 'promotion',
      borderTopColor: '#D93709',
      background: '#FA6338'
    })
  }
  if(configData.type == 'coupons'){
    if(configData.couponData.type == 0){
      Object.assign(subscriptData, {
        text: isContinue ? langText?.SHEIN_KEY_PWA_30112 : langText?.SHEIN_KEY_PWA_30107,
        // text: isContinue ? `One More Step to Enjoy FreeShipping  One More Step to Enjoy FreeShipping One More Step to Enjoy FreeShipping One More Step to Enjoy FreeShipping One More Step to Enjoy FreeShipping!` : `One More Step to Enjoy FreeShipping  One More Step to Enjoy FreeShipping One More Step to Enjoy FreeShipping One More Step to Enjoy FreeShipping One More Step to Enjoy FreeShipping!`,
        type: 'coupons',
        isSingleCoupon: configData.couponData.couponMultiple == 1 ? true : false,
        borderTopColor: '#D93709',
        background: '#FA6338    '
      })
    }
    if(configData.couponData.couponMultiple == 1 && configData.couponData.type != 0){
      if(configData.couponData.type == 1){
        const price = configData.couponData?.denomination
        Object.assign(subscriptData, {
          text: isContinue ? template(price, langText?.SHEIN_KEY_PWA_30114) : template(price, langText?.SHEIN_KEY_PWA_30115),
          type: 'coupons',
          isSingleCoupon: true,
          borderTopColor: '#D93709',
          background: '#FA6338    '
        })
      }
      if(configData.couponData.type == 2){
        const price = configData.couponData?.denomination
        Object.assign(subscriptData, {
          text: isContinue ? template(price, langText?.SHEIN_KEY_PWA_30116) : template(price, langText?.SHEIN_KEY_PWA_30117),
          type: 'coupons',
          isSingleCoupon: true,
          borderTopColor: '#D93709',
          background: '#FA6338    '
        })
      }
    }
    if(configData.couponData.couponMultiple > 1 && configData.couponData.type != 0){
      if(configData.couponData.type == 1){
        const price = configData.couponData?.denomination
        Object.assign(subscriptData, {
          text: template(price, langText?.SHEIN_KEY_PWA_30119),
          type: 'coupons',
          borderTopColor: '#D93709',
          background: '#FA6338    '
        })
      }
      if(configData.couponData.type == 2){
        const price = configData.couponData?.denomination
        Object.assign(subscriptData, {
          text: template(price, langText?.SHEIN_KEY_PWA_30120),
          type: 'coupons',
          borderTopColor: '#D93709',
          background: '#FA6338    '
        })
      }
    }
  }
  return subscriptData
}
