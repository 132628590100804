<template>
  <div
    v-cloak
    class="mshe-container mshe-bg-container mshe-all-height page__login_main-container"
    da-expose-code="page-login-expose-events"
  >
    <NewMainVue />
    <!-- 手机号码区码选择框 -->
    <ClientOnly>
      <template v-if="loadComponent">
        <AreaCodeList 
          v-if="commonAbt.PhoneAreaCodePopop == 'new'"
          :show="showAreaPop"
          :abbr="areaCode.abbr"
          :country-codes="countryCodes"
          :title="langText.SHEIN_KEY_PWA_26904"
          :PhoneAreaCodePopop="commonAbt.PhoneAreaCodePopop"
          @select-item="handleSelectItem"
          @close="closeArea"
        />
        <PhoneArea
          v-else
          :show="showAreaPop"
          :abbr="areaCode.abbr"
          :country-codes="countryCodes"
          :title="langText.SHEIN_KEY_PWA_26904"
          @select-item="handleSelectItem"
          @close="closeArea"
        />
      </template>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from '@login/hooks/utils'
import NewMainVue from './views/NewMainUI/index.vue'
import PhoneArea from './libs/PhoneAreaPop/index.vue'
import AreaCodeList from './libs/AreaCodeList/index.vue'
import { getPhoneAreaCodeList } from './service'
import { getQueryString } from '@shein/common-function'
import useLoadMainHook from '@login/hooks/useLoadMainHook.js'
import ClientOnly from 'vue-client-only'

const store = useStore()

const isloadExpose = ref(false)

const areaCode = computed(() => store.state.login.areaCode)
const langText = computed(() => store.state.login.langText)
const countryCodes = computed(() => store.state.login.countryCodes)
const loadComponent = computed(() => store.state.login.loadComponent)
const showIndex = computed(() => store.state.login.showIndex)
const commonAbt = computed(() => store.state.login.commonAbt)
const showAreaPop = computed(() => store.state.login.showAreaPop)
const preloadData = computed(() => store.state.login.preloadData)

const { handleForget, setSiteUidDefaultCode, setDefaultAreaCode, formatSupportCountry, getCartInfo, handleShowForgotPassword, exposeEvents } = useLoadMainHook()

watch(showIndex, (v) => {
  if(v && ! isloadExpose.value){
    setTimeout(() => {
      exposeEvents()
    }, 200)
    isloadExpose.value = true
  }
})

const initLoginInfo = async () => {
  const phoneArea = await getPhoneAreaCodeList({ countryList: preloadData?.value?.countryList })
  store.commit('login/setCountryCodes', phoneArea?.support || [])
  setDefaultAreaCode(phoneArea?.support || [])
  formatSupportCountry(phoneArea?.all || [])
  // 设置用户所在站点包含的所有国家，存储前五个，默认国家优先级最高
  setSiteUidDefaultCode(phoneArea?.all || [])

  const from = getQueryString({ key: 'from' })
  if(handleShowForgotPassword(from)){
    handleForget()
  }

  const tabIndex = getQueryString({ key: 'tabIndex' })
  if (tabIndex > 0) {
    store.commit('login/changePageShow', { index: tabIndex })
  }
}

const closeArea = () => {
  store.commit('login/setAreaCodePop', false)
}

const handleSelectItem = (item) => {
  store.commit('login/setAreaCode', item)
  if (typeof areaCode.value?.cb == 'function') {
    areaCode.value?.cb()
  }
  closeArea()
}

onMounted(() => {
  initLoginInfo()
  getCartInfo()
})

defineExpose({
  exposeEvents
})
// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'LoginMainPage'
}
</script>

<style lang="less">
@import "./styles/main.less";
</style>
