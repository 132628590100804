<template>
  <div class="reg-success__divider">
    <DividerLeftIcon class="divider_icon" />
    <div
      class="divider__text"
    >
      {{ langText?.SHEIN_KEY_PWA_29160 }}
    </div>
    <DividerRightIcon class="divider_icon" />
  </div>
</template>
<script setup>
import DividerLeftIcon from './Icon/DividerLeftIcon.vue'
import DividerRightIcon from './Icon/DividerRightIcon.vue'
defineProps({
  langText: {
    type: Object,
    default: () => {}
  }
})

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'DividerVue'
}
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.reg-success__divider {
  margin-top: 24px;
 display: flex;
 align-items: center;
 justify-content: center;
 color: @sui_color_gray_light1;
 font-size: 12 / @vw;
 font-weight: 400;
 .divider_icon{
  width: 24 / @vw;
  height: 2 / @vw;
 }
 .divider__text{
    margin: 0 1 / @vw;
 }
}
</style>
