<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 351 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-inside-1_1780_1395"
      fill="white"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0L100.042 0C100.395 2.97721 102.928 5.28613 106 5.28613C109.072 5.28613 111.605 2.97721 111.958 0L343 0C347.418 0 351 3.58172 351 8V100C351 104.418 347.418 108 343 108L111.829 108C111.941 107.542 112 107.064 112 106.571C112 103.258 109.314 100.571 106 100.571C102.686 100.571 100 103.258 100 106.571C100 107.064 100.059 107.542 100.171 108L8.00001 108C3.58173 108 0 104.418 0 100L0 8C0 3.58172 3.58172 0 8 0Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0L100.042 0C100.395 2.97721 102.928 5.28613 106 5.28613C109.072 5.28613 111.605 2.97721 111.958 0L343 0C347.418 0 351 3.58172 351 8V100C351 104.418 347.418 108 343 108L111.829 108C111.941 107.542 112 107.064 112 106.571C112 103.258 109.314 100.571 106 100.571C102.686 100.571 100 103.258 100 106.571C100 107.064 100.059 107.542 100.171 108L8.00001 108C3.58173 108 0 104.418 0 100L0 8C0 3.58172 3.58172 0 8 0Z"
      fill="#FFF6F3"
    />
    <path
      d="M100.042 0L100.539 -0.0588634L100.486 -0.5L100.042 -0.5V0ZM111.958 0V-0.5L111.514 -0.5L111.461 -0.0588634L111.958 0ZM111.829 108L111.343 107.881L111.192 108.5H111.829V108ZM100.171 108V108.5H100.808L100.657 107.881L100.171 108ZM100.042 -0.5L8 -0.5V0.5L100.042 0.5V-0.5ZM106 4.78613C103.184 4.78613 100.862 2.66972 100.539 -0.0588634L99.5455 0.0588634C99.9279 3.28469 102.671 5.78613 106 5.78613V4.78613ZM111.461 -0.0588634C111.138 2.66972 108.816 4.78613 106 4.78613V5.78613C109.329 5.78613 112.072 3.28469 112.454 0.0588634L111.461 -0.0588634ZM343 -0.5L111.958 -0.5V0.5L343 0.5V-0.5ZM351.5 8C351.5 3.30558 347.694 -0.5 343 -0.5V0.5C347.142 0.5 350.5 3.85786 350.5 8H351.5ZM351.5 100V8H350.5V100H351.5ZM343 108.5C347.694 108.5 351.5 104.694 351.5 100H350.5C350.5 104.142 347.142 107.5 343 107.5V108.5ZM111.829 108.5L343 108.5V107.5L111.829 107.5V108.5ZM111.5 106.571C111.5 107.023 111.446 107.462 111.343 107.881L112.315 108.119C112.436 107.622 112.5 107.104 112.5 106.571H111.5ZM106 101.071C109.038 101.071 111.5 103.534 111.5 106.571H112.5C112.5 102.981 109.59 100.071 106 100.071V101.071ZM100.5 106.571C100.5 103.534 102.962 101.071 106 101.071V100.071C102.41 100.071 99.5 102.981 99.5 106.571H100.5ZM100.657 107.881C100.554 107.462 100.5 107.023 100.5 106.571H99.5C99.5 107.104 99.5642 107.622 99.6854 108.119L100.657 107.881ZM8.00001 108.5L100.171 108.5V107.5L8.00001 107.5V108.5ZM-0.5 100C-0.5 104.694 3.30559 108.5 8.00001 108.5V107.5C3.85787 107.5 0.5 104.142 0.5 100H-0.5ZM-0.5 8L-0.5 100H0.5L0.5 8H-0.5ZM8 -0.5C3.30558 -0.5 -0.5 3.30558 -0.5 8H0.5C0.5 3.85787 3.85786 0.5 8 0.5V-0.5Z"
      fill="#FFE2CF"
      mask="url(#path-1-inside-1_1780_1395)"
    />
    <line
      opacity="0.4"
      x1="106.25"
      y1="14.25"
      x2="106.25"
      y2="92.75"
      stroke="#FF3F27"
      stroke-opacity="0.4"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-dasharray="3.48 3.48"
    />
  </svg>
</template>

<script>
export default {
  name: 'CompatibleCouponBg'
}
</script>
