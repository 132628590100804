<template>
  <div class="page__login_etraInfo">
    <!-- 访客模式客服机器人入口 -->
    <div
      class="mshe-text-center login-robot-entry"
    >
      <span @click="toggleVisitConfirmModal">{{ langText.SHEIN_KEY_PWA_20044 }} <i class="suiiconfont sui_icon_nav_back_24px_mir"></i></span>
    </div>
    <!-- 客服机器人 -->
    <s-dialog 
      :visible="isShowVisitConfrimModal" 
      :append-to-body="true"
    >
      <div class="visitor-txt">
        {{ langText.SHEIN_KEY_PWA_20045 }}
      </div>
      <template slot="footer">
        <s-button-group hor>
          <s-button-group-item
            :type="['H72PX']"
            @click="toggleVisitConfirmModal"
          >
            {{ langText.SHEIN_KEY_PWA_14830 }}
          </s-button-group-item>
          <s-button-group-item
            :type="['H72PX', 'primary']"
            @click="toOnlineHelp"
          >
            {{ langText.SHEIN_KEY_PWA_15146 }}
          </s-button-group-item>
        </s-button-group>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import schttp from 'public/src/services/schttp'

export default {
  name: 'LoginExtraInfo',
  data: () => ({
    isShowVisitConfrimModal: false,
    loginSecondConfirm: true //是否需要二次确认 true 需要 false 不需要
  }),
  computed: {
    ...mapState('login', ['loginPopConfig', 'langText']),
  },
  mounted(){
    this.getSecondConfirm()
  },
  methods: {
    // 切换客服机器人访问弹窗
    async toggleVisitConfirmModal () {
      if(!this.loginSecondConfirm){
        const { jumpToRobot } = await import('@customerService/botLogic')
        jumpToRobot('NoLoginPage')
      }else this.isShowVisitConfrimModal = !this.isShowVisitConfrimModal
      if (this.isShowVisitConfrimModal) {
        window.sa?.('send', { activity_name: 'click_no_login_pop', activity_param: {} })
      }
    },
    // 跳转客服机器人
    async toOnlineHelp () {
      window.sa?.('send', { activity_name: 'click_no_login_pop_yes', activity_param: {} })
      this.toggleVisitConfirmModal()
      SHEIN_LOGIN.show({ show: false })
      if(this.loginPopConfig?.toRobot) {
        const { jumpToRobot } = await import('@customerService/botLogic')
        jumpToRobot('NoLoginPage')
      }
      
    },
    async getSecondConfirm(){
      //获取当前是否二次确定
      const res = await schttp({ url: '/api/robot/channel/init/query' })
      const { getCustomerChannel } = res
      if(getCustomerChannel?.code == 0){
        if(getCustomerChannel?.info?.v2) this.loginSecondConfirm = getCustomerChannel?.info?.v2?.no_login_second_confirm
      }
    }
  }
}
</script>

