import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-8' })


export const exposeEmailLoginSubcribeCheckbox = (type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_email_login_subcribe_checkbox',
      subcribe_type: type ? 'default_not_subcribe' : 'default_subcribe'
    },
  })
}
