<template>
  <div>
    <risk-validte
      ref="RiskValidate"
      :lang-text="langText"
    />
  </div>
</template>

<script>
import commonRiskValidate from 'public/src/pages/common/RiskCommon/mixin/commonRiskValidate.js'
export default {
  name: 'TemporaryIndex',
  mixins: [commonRiskValidate],
  props: {
    langText: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

